@import 'src/styles/_mixins.scss';

.migrate-modal.ant-modal {
  .ant-modal-content {
    padding: 20px 36px;
    .ant-modal-body {
      .migrate-modal__content {
        .migrate-modal__title {
          text-align: center;
          margin-top: 0;
        }
        .migrate-modal__actions {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .ant-btn {
            width: 100px;
          }
          .migrate-modal__ok-btn {
            margin-left: 16px;
          }
        }
      }
    }
  }
}
