
[theme="dark"] {
  .loading-modal.ant-modal {
    .ant-modal-content {
      background-color: var(--primary-bg-dark);

      .ant-modal-body {
        .loading-modal__title {
          color: #ffffff;
        }
      }
    }
  }
}

[theme="light"] {
  .loading-modal.ant-modal {
    .ant-modal-content {
      background-color: #ffffff;

      .ant-modal-body {
        .loading-modal__title {
          color: #222B45;
        }
      }
    }
  }
}

.loading-modal.ant-modal {
  .ant-modal-content {
    padding: 35px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .loading-modal__title {
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
      }

      .loading-modal__icon {
        color: #3366FF;
        stroke-width: 50;
        stroke: #3366FF;
        margin-top: 16px;

        svg {
          font-size: 40px;
        }
      }
    }
  }
}
