@import 'src/styles/_mixins.scss';

.new-shipment-main-info-page {
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__title {
    text-align: center;
    font-size: 26px;
    font-weight: 800;
  }

  .new-shipment-main-info-page-form {

    &__left-item {
      padding-right: 12px;
    }

    &__right-item {
      padding-left: 12px;
    }

    &__checkbox-field {
      display: flex;
      text-align: left;
    }

    &__add-vehicle-btn {
      width: 100%;
      background-color: var(--primary-input-bg--dark);
    }
    &__add-vehicle-text {
      color: var(--primary);
    }
    &__btn {
      width: 400px;
      @include for-smartphone-only {
        width: 80%;
      }
    }
  }

  &__price {
    .new-shipment-main-info-page__price-label {
      font-size: 26px;
      line-height: 32px;
      font-weight: 500;
    }
    .new-shipment-main-info-page__price-value {
      font-size: 26px;
      line-height: 32px;
      font-weight: 700;
      margin-left: 16px;
    }
  }

  .new-shipment-main-info-page__shipment-card {
    margin-bottom: 18px;
    align-items: center;
    justify-content: center;
    .new-shipment-main-info-page__shipment-card-left {
      padding-right: 12px;
    }
    .new-shipment-main-info-page__shipment-card-right {
      padding-left: 12px;
    }
  }
}

[theme="dark"] {
  .new-shipment-main-info-page {
    background-color: var(--primary-bg-dark);
    &__title {
      color: var(--primary-text-color--dark);
    }

    .new-shipment-main-info-page-form {
      &__label {
        color: var(--primary-text-color--dark);
      }

      &__shipping-date-description {
        color: var(--primary-text-color--dark);
      }

      &__checkbox-field {
        span {
          color: var(--primary-text-color--dark);
        }
      }

      &__remove-btn {
        margin-left: 8px;
        color: var(--primary-text-color--dark);
      }

      &__price {

      }

      .new-shipment-main-info-page__form__zip-input {
        .ant-input-suffix {
          color: var(--secondary-text-color--light);
        }
      }
    }
  }
}

[theme="light"] {
  .new-shipment-main-info-page {
    background-color: var(--primary-bg-light);
    &__title {
      color: var(--primary-text-color--light);
    }

    .new-shipment-main-info-page-form {
      &__label {
        color: var(--primary-text-color--light)
      }

      &__shipping-date-description {
        color: var(--primary-text-color--light)
      }

      &__checkbox-field {
        span {
          color: var(--primary-text-color--light)
        }
      }

      &__remove-btn {
        margin-left: 8px;
        color: var(--primary-text-color--light);
      }

      .new-shipment-main-info-page__form__zip-input {
        .ant-input-suffix {
          color: var(--secondary-text-color--light);
        }
      }
    }
  }
}
