@import 'src/styles/_mixins';

[theme="light"] {

  .transaction-detail-actions {
    .transaction-detail-actions__group {
      .transaction-detail-actions__group__label {
        color: var(--secondary-text-color--light);
      }

      .transaction-detail-actions__group__body {
        border-color: var(--continue);
      }
    }
  }

  .payment-transfer-history-modal.ant-modal {
    .ant-modal-content {
      .ant-modal-header {
        background-color: #FFFFFF;
        .ant-modal-title {
          color: #222B45;
        }
      }
    }
  }
}

[theme="dark"] {
  .transaction-detail-actions {
    .transaction-detail-actions__group {
      .transaction-detail-actions__group__label {
        color: var(--secondary-text-color--light);
      }

      .transaction-detail-actions__group__body {
        border-color: var(--box-border-color--dark);
      }
    }
  }

  .payment-transfer-history-modal.ant-modal {
    .ant-modal-content {
      .ant-modal-header {
        background-color: #222B45;
        .ant-modal-title {
          color: #FFFFFF;
        }
      }
    }
  }
}

.transaction-detail-actions {
  margin-top: 20px;

  .transaction-detail-actions__content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .tap-to-see-more {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 24px;
    cursor: pointer;
  }

  .transaction-detail-actions__group {
    width: 100%;
    .transaction-detail-actions__group__label {
      color: var(--secondary-text-color--light);
      font-size: 16px;
      font-weight: bold;
    }

    .transaction-detail-actions__group__body {
      margin-top: 4px;
      border: solid 2px var(--box-border-color--dark);
      padding: 20px 10px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;

      .transaction-detail-actions__group__payment-transfer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .transaction-detail-actions__group__payment-transfer-history {
        width: 100%;
        color: var(--tertiary);
        &__details-btn {
          width: 70px;
          min-height: 24px;
          height: 24px;
          color: var(--primary) !important;
          border-radius: 10px;
          font-size: 12px;
          margin: 0px 0px 24px 24px !important;
        }
      }
    }
  }

  .ant-btn.kuaay-btn {
    white-space: break-spaces;
    height: auto;
    width: 400px;
    &:not(:first-child) {
      margin-top: 16px;
    }
    @include for-smartphone-only {
      width: calc(100% - 32px);
    }
  }
}

.email-pdf-modal {
  .ant-modal-content {
    .email-pdf-modal__title {
      font-size: 15px;
      font-weight: 400;
    }
    .email-pdf-modal__form {
      margin-top: 16px;
      .email-pdf-modal__form-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .ant-btn.kuaay-btn {
          width: 100px;
        }
      }
    }
  }
}

.payment-transfer-history-modal {
  &__content {
    color: var(--tertiary);
  }

  ul {
    margin: 0;
  }

  &__label {

  }
}
