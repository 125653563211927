@import 'src/styles/_mixins.scss';

.description-form__btn {
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: 0 auto 16px auto;
    width: calc(var(--app-width) - 100px);
    background-color: #EFF0F6;
    transition: background-color 0.3s, transform 0.3s !important; 

    @include for-tablet-and-up {
      width: 400px;
    }
    &:hover {
      background-color: #D6ff0f !important; 
      transform: scale(1.1) !important; 
      color: #000 !important;

    }
  }

  .slider-wrapper {
    display: flex;
    align-items: center;
  
    span {
      margin: 0 10px;
      color: white;
    }
  
    .ant-slider {
      flex-grow: 1;
    }
    .ant-form-item{
      background: #EFF0F6 !important;
    }
  }

  .specific-vehicle-information-page{
    background-color: rgba(217, 217, 217, 0.16) !important;
    border-radius: 8px; 
  }

  .specific-vehicle-information-page__title {
    margin: 0 !important;
    text-align: left !important; 
    width: 100% !important; 
    position: relative;
    overflow: hidden;
  }
  
  .specific-vehicle-information-page__form__vin-description {
    margin: 0 !important; 
    text-align: left !important; 
    width: 100% !important;
  }


  
  .page-content {
    // position: absolute;
    top: 0;
    width: 100%;
    transition: transform 2s ease-in-out;
  }
  
  .page-content.swipe-in {
    transform: translateX(0%);
  }
  
  .page-content.swipe-out {
    transform: translateX(-100%);
  }


  .description-builder {
    overflow-x: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 16px 0;
  }
  
  .left-section, .right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  
    .pull-vin-btn, .description-form__btn {
      margin-top: 16px;
      width: 100%;
      &:hover {
        background-color: #D6ff0f !important; // Change background color on hover
        transform: scale(1.1) !important; // Enlarge button on hover
        color: #000; // Change text color to black on hover

      }
    }
  }
  
  .or-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
  }

  .left-section .pull-vin-btn {
    top: 73px; 
    left: 0;
    @media (max-width: 767px) {
      top: 0px;
      bottom: 3px;
    }
  }

  .spinner{
    overflow-x: hidden !important;
  }

  .ant-spin-nested-loading .ant-spin-container {
    padding-top: 25px !important;
    padding-bottom:25px !important;
    overflow-x: hidden !important;
  }

  @media (max-width: 767px) {
    .or-text {
      padding: 12px;
    }
  }
  

