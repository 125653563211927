@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .payment-transfer-detail-page {
    .payment-transfer-detail__label {
      color: var(--secondary-text-color--light);
    }
  }
}

[theme="light"] {
  .payment-transfer-detail-page {
    .payment-transfer-detail__label {
      color: var(--secondary-text-color--light);
    }
  }
}

.payment-transfer-detail-page {
  padding-bottom: 100px;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .payment-transfer-detail__label {
    text-align: right;
    padding-right: 12px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
  }
  .payment-transfer-detail__value {
    text-align: right;
    padding-right: 12px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
  }

  .payment-transfer-detail__btn-container {
    display: flex;
    margin-top: 36px;
    align-items: center;
    justify-content: center;
    width: 100%;

    .payment-transfer-detail__checkout-btn {
      width: 80%;
    }
  }
}
