
[theme="dark"] {
}

[theme="light"] {
}

.k-modal.ant-modal {
  width: 100vw;
  height: 100vh;
  margin: 0;
  top: 0;
  left: 0;
  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
    padding: 0;
    .k-modal__content {

    }
  }
}

