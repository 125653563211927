@import 'src/styles/_mixins.scss';

.home-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100vh; 
  background-color: #1B1B1B;
  

  @include for-mobile-device {
    padding-bottom: 80px;
    // padding-top: 10vh;
  }
}

.widget-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  
  

  .widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: calc(33.33% - 40px); 
    max-width: 300px;
    min-width: 250px;
    height: 200px;
    background-color: #2B6BE4;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    h3 {
      margin: 0;
      font-size: 1.5rem;
    }

    p {
      margin: 5px 0;
    }

    &:hover {
      transform: scale(1.05);
      background-color: #D6FF0F ; 
      color: black;
    }
  }

  // Adjust layout for smaller screens
  @media (max-width: 1023px) {
    .widget {
      flex-basis: calc(33.33% - 20px); // 2 widgets per row on medium screens
    }
  }

  @media (max-width: 768px) {
    .widget {
      flex-basis: 100%; // Stacks the widgets vertically on smaller screens
    }
  }
}


@media (max-width: 564px) {
  .widget-container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      gap: 50px;
      // align-content: flex-start;
      flex-direction: column;
      // margin-top: 400px;
      padding: 50px;
      overflow-y: auto;

  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh; 
}

@media (max-width: 1023px) {
  .app-wrapper{
    padding-bottom: 80px;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
    overflow-y: hidden; // Allows scrolling if content overflows
    box-sizing: border-box; // Ensure padding is included in the height calculation
  }
}

@media (max-width: 590px) {
  .home-wrapper{
    -webkit-overflow-scrolling: touch;
    padding: 20px;
    display:block;
    height: auto;
    //overflow-y: hidden; 
  }
}
// @media (max-width: 1023px) {
//   .app-wrapper{
//     padding-top: 100px;
//     justify-content: flex-end;
//   }
// }