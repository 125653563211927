.verify-identity-widget {
    display: flex; 
    align-items: center;
    margin-inline-start: 0 !important; 
    justify-content: space-between;
    width: 400px; 
    border-radius: 16px;
    background-color: #2c2c2c;
    color: white;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    flex-wrap: wrap; 
    // &:hover {
    //     background: #0056b3; // Darker blue for hover effect
    //     transform: scale(1.05);
    //     color:black
    //   }

    @media (max-width: 550px) {
      width: 100%;
      flex-direction: column; 
      align-items: center;
      text-align: center;
      padding: 12px;
      width: 90%; 
      margin: 8px 0; 
      &__icon {
        margin-right: 0; 
        margin-bottom: 12px; 
        width: 48px; 
        height: 48px;
      }
  
      &__content {
        align-items: center;
        width: 100%; 
      }
  
      &__text {
        font-size: 1rem;
        margin-bottom: 8px;
      }
  
      &__button {
        align-self: center; 
        margin-top: 12px;
        font-size: 0.9rem;
        padding: 6px 12px;
      }
    }
  }

  
  // Icon styling
  .verify-identity-widget__icon {
    width: 110px;
    height: 150px;
    margin-right: 20px; 
    @media (max-width: 550px) {
        margin-right: 0px;
    }

  }
  
  // Text and button container
  .verify-identity-widget__content {
    flex: 1; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
    
  }
  
  // Description text
  .verify-identity-widget__text {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
  }
  
  // Button styling
  .verify-identity-widget__button {
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 8px;
    width: 100px;
    height: 40px;
    color: white;
    font-size: 14px;
    justify-content: center; 

    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      color:black;
      background: #0056b3; 
      transform: scale(1.05)
    }
  }
  