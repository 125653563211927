@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .main-vehicle-info {
    background-color: var(--primary-bg-dark);
    .main-vehicle-info__title {
      color: var(--primary-text-color--dark);
    }

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--dark)
      }
    }
  }
}

[theme="light"] {
  .main-vehicle-info {
    background-color: var(--primary-bg-light);
    .main-vehicle-info__title {
      color: var(--primary-text-color--light);
    }

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--light)
      }
    }
  }
}

.main-verification-container{
  text-align: center;
  max-height: 85% !important;
  display: flex;
  align-items: flex-start;

  @include for-desktop-and-upper {
    height: 85vh;
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__title {
    font-size: 26px;
    font-weight: 800;
  }

}

.id_container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;

  img {
    max-height: 200px;
    width: calc(50% - 10px); 
    object-fit: cover;
    margin: 5px; 
    border: 1px solid #ccc;
  }
}

.verification-details {
  background-color: #2c2c2c; 
  border-radius: 10px;
  padding: 10px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: auto !important;
  overflow-y: auto; 

  @include for-desktop-and-upper {
    max-height: 800px !important;
  }
  
}

.button-no-style {
  background: none;
  border: none;
  cursor: pointer;
}

.button-black {
  color: #1677ff;
}

.button-red {
  color: red !important;
}

.custom-table .ant-table .ant-empty-description {
  background-color: #2c2c2c !important;
  color: white !important;
  
}

.custom-table .ant-table-thead > tr > th {
  background-color: #2c2c2c !important; 
  color: white !important; 
}

.custom-table .ant-table-tbody > tr > td {
  background-color: #2c2c2c !important; 
  color: white !important; 
}

.custom-table .ant-table-tbody > tr:hover > td {
  background-color: #3a3a3a !important; 
}

.custom-table .ant-table-tbody > tr > td a {
  color: #1e90ff !important; 
}

.custom-table .ant-table-pagination {
  color: white !important;
}

.custom-table .ant-table-pagination .ant-pagination-item-active {
  background-color: #3a3a3a !important; 
}

.custom-table .ant-table-cell{
  border-start-end-radius: 0 !important;
  border-start-start-radius: 0 !important;
}