[theme="dark"] {
  .kuaay-password-input.ant-input-password {
    border-color: var(--primary);
    color: #ffffff;

    &.kuaay-password-input-v2 {
      &:not(.ant-input-affix-wrapper-focused) {
        border-color: var(--box-border-color--dark);

      }
      &.ant-input-affix-wrapper-focused {

      }

    }

    &.ant-input-affix-wrapper-focused {
      background-color: #222B45;
      .anticon {
        svg {
          color: var(--primary);
        }
      }

    }
    input {
      border: none;
      // background-color: var(--primary-input-bg--dark);
      background-color: #FDFFF5;
      color: var(--primary-text-color--light);
      &:active, &:focus {
        // background-color: #222B45;
        background-color: #a3a3a2;
      }
      &::-webkit-input-placeholder {
        color: #8e94a3;
      }

      &::placeholder {
        color: #8e94a3;
      }
    }

    .anticon {
      svg {
        color: #8e94a3;
      }
    }
  }
}

[theme="light"] {
  .kuaay-password-input.ant-input-password {
    border-color: var(--secondary-border-color--light);
    color: #222B45;


    &.ant-input-affix-wrapper-focused {
      background-color: #FFFFFF;
      border-color: var(--primary);
      .anticon {
        svg {
          color: var(--primary);
        }
      }

    }

    input {
      background-color: #F7F9FC;

      &::-webkit-input-placeholder {
        color: #5f6574;
      }

      &::placeholder {
        color: #5f6574;
      }
    }
  }
}

.kuaay-password-input.ant-input-password {
  border-width: 1px;
  border-style: solid;
  min-height: 40px;
  border-radius: 4px;
  font-size: 15px;
  padding: 0;
  position: relative;
  background-color: transparent;
  .ant-input-suffix {
    position: absolute;
    right: 8px;
    top: 0;
    min-height: 40px;
    svg {
      font-size: 20px;
    }
  }
  input {
    padding: 4px 24px 4px 11px;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    min-height: 40px;

    &[type='password'] {
      font-family: Verdana, sans-serif;
      letter-spacing: 0.125em;
      &::-webkit-input-placeholder {
        font-family: Arial, sans-serif;
        letter-spacing: 0;
      }

      &::placeholder {
        font-family: Arial, sans-serif;
        letter-spacing: 0;
      }
    }
  }
}
