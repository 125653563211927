@import 'src/styles/_mixins';

[theme="dark"] {
  .transaction-report-issue-modal.ant-modal {
    .ant-modal-content {

      .ant-modal-header {
        background-color: #222B45;
        .ant-modal-title {
          color: #FFFFFF;
        }
      }
    }
  }
}

[theme="light"] {
  .transaction-report-issue-modal.ant-modal {
    .ant-modal-content {
      .ant-modal-header {
        background-color: #FFFFFF;
        .ant-modal-title {
          color: #222B45;
        }
      }
    }
  }
}

.transaction-report-issue-modal.ant-modal {
  .ant-modal-content {

    .ant-modal-header {
      margin-bottom: 50px;
    }

    .ant-modal-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      @include for-desktop-and-upper {
        max-width: var(--max-form-width);
        margin: auto;
      }
      .transaction-report-issue-modal__content {
        .transaction-report-issue-modal__info-form {
          width: 100%;
          .transaction-report-issue-modal__btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .transaction-report-issue-modal__submit-btn {
              margin-top: 30px;
              margin-bottom: 30px;
              width: 300px;
            }
          }
        }
      }
    }
  }
}
