
@import 'src/styles/_mixins.scss';

.specific-vehicle-information-page {
    padding-bottom: 20px;

    @include for-desktop-and-upper {
      margin: auto;
      max-width: var(--max-form-width);
    }

    &__title {
      text-align: center;
      font-size: 26px;
      font-weight: 800;
    }

    &__continue-button {
        width: 100%;
    }

    &__form {
        margin-top: 8px;
        &__vin-description {
          text-align: left;
        }

        &__vin-lookup {
            input {
              cursor: pointer;
            }
            caret-color: transparent;
        }

        &__extension-waiver-text, &__nada-text {
          margin-top: -8px;
          .kuaay-form__text-under-input {
            font-size: 10px;
            a {
              font-size: 11px;
            }
          }
        }

        &__extension-waiver-input {
          input {
            cursor: pointer;
            caret-color: transparent;
          }
        }

        &__kei-notice-info {
          border: 1px solid var(--secondary);
          align-items: flex-start;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 5px;
          padding-right: 5px;
          text-align: left;
          .ant-typography {
            font-size: 13px;
          }
        }

        &__kei-notice-check {
          cursor: pointer;
          width: 100%;
          display: flex;
          align-items: center;
          text-align: left;
          .ant-form-item {
            margin-bottom: 0 !important;
          }
          div {
            margin: 0 2px;
          }
          .kei-notice-check__label {
            font-size: 13px;
          }
        }

        &__kei-notice-check--error {
          span {
            color: var(--danger) !important;
          }
        }

        &__nada-check {
          font-size: 10px;
          display: flex;
          text-align: left;
          margin-top: 8px;
          span {
            font-size: 10px;
          }

          .ant-form-item-control-input+div {
            display: none !important;
          }
        }

        &__nada-check--error {
          span {
            color: var(--danger) !important;
          }
        }
    }
  }

  [theme="dark"] {
    .specific-vehicle-information-page {
      background-color: var(--primary-bg-dark);
      &__title {
        color: var(--primary-text-color--dark);
      }

      &__form {
        &__extension-waiver-text {
          .kuaay-form__text-under-input {
            a {
              color: green;
            }
          }
        }
      }
    }
  }

  [theme="light"] {
    .specific-vehicle-information-page {
      &__title {
        color: var(--primary-text-color--light);
      }

      &__form {
        &__extension-waiver-text {
          .kuaay-form__text-under-input {
            a {
              color: blue;
            }
          }
        }
      }
    }
  }
