@import 'src/styles/_mixins.scss';

.new-shipment-payment-page {
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .new-shipment-payment-page__title {
    text-align: center;
  }

  .new-shipment-payment-page__content-wrapper {
    margin-top: 64px;
    padding: 24px 32px;
    border-style: solid;
    border-width: 5px;
    border-radius: 4px;
  }

  .new-shipment-payment-page__fee-wrapper {
    padding: 0 12px;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .fee__title {
      font-size: 20px;
      font-weight: 400;
    }
    .fee__amount {
      font-size: 25px;
      font-weight: 400;
    }
    .total-fee__title {
      font-size: 25px;
      font-weight: 400;
    }
    .total-fee__amount {
      font-size: 30px;
      font-weight: bold;
    }
  }
  .new-shipment-payment-page__actions {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 360px;

    @include for-smartphone-only {
      max-width: calc(var(--app-width) - 45%);
    }

  }

}

[theme="dark"] {
  .new-shipment-payment-page {
    background-color: var(--primary-bg-dark);

    .new-shipment-payment-page__content-wrapper {
      border-color: var(--box-border-color--dark);
    }
  }
}

[theme="light"] {
  .new-shipment-payment-page {
    background-color: var(--primary-bg-light);
    .new-shipment-payment-page__content-wrapper {
      border-color: var(--secondary-border-color--light);
    }
  }
}
