@import 'src/styles/_mixins.scss';

.main-verification-container{
  text-align: center;
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__title {
    font-size: 26px;
    font-weight: 800;
  }

  &__form-checkbox-field {
    display: flex;
    text-align: left;
    span.ant-typography {
      margin-left: 6px;
    }
  }

}




[theme="dark"] {
  .main-vehicle-info {
    background-color: var(--primary-bg-dark);
    .main-vehicle-info__title {
      color: var(--primary-text-color--dark);
    }

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--dark)
      }
    }
  }
}

[theme="light"] {
  .main-vehicle-info {
    background-color: var(--primary-bg-light);
    .main-vehicle-info__title {
      color: var(--primary-text-color--light);
    }

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--light)
      }
    }
  }
}



.verification-details {
  background-color: #FDFFF5; 
  border-radius: 10px; 
  padding: 10px 5px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  overflow-y: auto; 
  margin-top: 5px;
  margin-bottom: 10px;
  .header {
    color: grey !important;; 
    text-align: center; 
  }
  @include for-desktop-and-upper {
    padding: 10px; // Desktop padding
  }
 
}

.id_container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;

  img {
    max-height: 200px;
    width: calc(50% - 10px); 
    object-fit: cover;
    margin: 5px; 
    border: 1px solid #ccc;
  }
}

.verify-identity-details {
  .back-button {
    position: fixed;
    top: 0; 
    left: 0;
    z-index: 1;
    background: transparent;
    border: none;

    .anticon {
      color: #FDFFF5;
      font-size: 24px;
    }

    @media (min-width: 1000px) { 
      top: 150px; 
    }
  }
}

.custom-input::placeholder {
  color: white; 
  opacity: 0.7; 
}

.ant-input-disabled {
  background-color: transparent !important; 
  color: white !important; 
}

.ant-input[disabled] {
  cursor: not-allowed;
}

.custom-input-group-addon {
  background-color: transparent; 
  color: white !important; 
  border: none;
}
.custom-form-container .ant-input-affix-wrapper,
.custom-form-container .ant-input {
  background-color: #2C2C2C !important;
  color: white !important;
}

