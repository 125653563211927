textarea.kuaay-textarea.ant-input {
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  min-height: 40px;
  border-radius: 4px;
  font-size: 15px;

  :is(textarea) {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    min-height: 40px;
  }
}

[theme="dark"] {
  textarea.kuaay-textarea.ant-input {
    border-color: var(--primary);
    color: #1B1B1B;
    background-color: #FDFFF5;

    &.ant-input-disabled {
      border-color: var(--box-border-color--dark) !important;
      color: var(--field-color--disabled--dark) !important;

      &::-webkit-input-placeholder {
        color: rgba(143, 155, 179, 0.48);
      }

      &::placeholder {
        color: rgba(143, 155, 179, 0.48);
      }
    }

    &:active, &:focus {
      background-color: #a3a3a2;
    }

    &::-webkit-input-placeholder {
        color:#1B1B1B
    }

    &::placeholder {
      color: #757576;
    }

  }
}

[theme="light"] {
  textarea.kuaay-textarea.ant-input {
    border-color: var(--primary);
    color: #222B45;
    background-color: #F7F9FC;


    &.ant-input-disabled {
      border-color: var(--box-border-color--light) !important;

      color: var(--field-color--disabled--light);

      &::-webkit-input-placeholder {
        color: rgba(143, 155, 179, 0.48);
      }

      &::placeholder {
        color: rgba(143, 155, 179, 0.48);
      }
    }


    &:active, &:focus {
      background-color: #FFFFFF;
    }

    &::-webkit-input-placeholder {
      color: var(--secondary-text-color--light);
    }

    &::placeholder {
      color: var(--secondary-text-color--light);
    }
  }
}
