@import 'src/styles/_mixins.scss';

.info-modal.ant-modal {
  .ant-modal-content {
    padding: 20px 36px;
    border-radius: 4px;
    .ant-modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include for-desktop-and-upper {
        max-width: var(--max-form-width);
        margin: auto;
      }

      .info-modal__title {
        font-size: 16px;
        margin-bottom: 24px;
        font-weight: bold;
      }

      .info-modal__text {
        white-space: pre-wrap;
      }

      .info-modal__btn {
        margin-top: 24px;
        width: 100px;
      }
    }
  }
}
