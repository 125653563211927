@import './_variables.scss';

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.63);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent; //rgba(204, 204, 204, 0.25)
    scrollbar-color: rgba(0, 0, 0, 0.63) transparent;
    scrollbar-width: thin;
  }
}


@mixin for-smartphone-only {
  @media screen and (max-width: $phone-max-width) {
    @content
  }
}

@mixin for-mobile-device {
  @media screen and (max-width: $tablet-max-width) {
    @content
  }
}

@mixin for-mobile-to-desktop {
  @media screen and (max-width: $desktop-max-width) {
    @content
  }
}

@mixin for-tablet-only {
  @media screen and (max-width: $tablet-max-width) and (min-width: $tablet-min-width) {
    @content
  }
}

@mixin for-tablet-and-desktop {
  @media screen and (max-width: $desktop-max-width) and (min-width: $tablet-min-width) {
    @content
  }
}

@mixin for-tablet-and-up {
  @media screen and (min-width: $tablet-min-width) {
    @content
  }
}

@mixin for-desktop-only {
  @media screen and (max-width: $desktop-max-width) and (min-width: $desktop-min-width) {
    @content
  }
}

@mixin for-desktop-and-upper {
  @media screen and (min-width: $desktop-min-width) {
    @content
  }
}

@mixin for-lg-desktop {
  @media screen and (min-width: $lg-desktop-min-width) {
    @content
  }
}







