.kuaay-autocomplete.ant-select-auto-complete {
  .ant-select-selector {
    padding: 0;
    position: relative;
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    min-height: 40px;
    border-radius: 4px;
    font-size: 15px;
    .ant-select-selection-search {
      inset-inline-start: 0;
      inset-inline-end: 0;
      input {
        padding: 4px 11px 4px 11px;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        min-height: 40px;
        font-size: 15px;
        // color: var(--primary);
        color: black;
      }
    }
    .ant-select-selection-placeholder {
      padding: 4px 11px 4px 11px;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      min-height: 40px;
    }
  }
  .ant-select-clear {
    position: absolute;
    right: 10px;
    top: 16px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    svg {
      font-size: 20px;
    }
  }

}

[theme="dark"] {
  .kuaay-autocomplete.ant-select-auto-complete {
    // background-color: var(--primary-input-bg--dark);
    background-color: #FDFFF5 ;
    .ant-select-selector {
      border-color: var(--primary);
      color: var(--primary-input-bg-dark-focused);
    }

    .ant-select-selection-placeholder {
      color: #8e94a3;
    }
    &.ant-select-focused {
      background-color: #a3a3a2;
      // background-color: var(--primary-input-bg-dark-focused);
    }
    &.ant-select-disabled {
      .ant-select-selector {
        border-color: var(--box-border-color--dark);
        color: var(--field-color--disabled--dark);
        background-color: transparent !important;
      }
    }

    .ant-select-clear {
      svg {
        color: var(--primary);
      }
    }

  }
}

[theme="light"] {
  .kuaay-autocomplete.ant-select-auto-complete {
    background-color: #F7F9FC;
    .ant-select-selector {
      border-color: var(--primary);
      color: var(--primary-input-bg-dark-focused);
    }

    .ant-select-selection-placeholder {
      color: var(--secondary-text-color--light);
    }
    &.ant-select-focused {
      background-color: #FFFFFF;
    }
    &.ant-select-disabled {
      .ant-select-selector {
        color: var(--field-color--disabled--light);
        border-color: var(--box-border-color--light) !important;
        background-color: transparent !important;
      }
    }

    .ant-select-clear {
      svg {
        color: var(--secondary-text-color--light);
      }
    }
  }
}
