@import 'src/styles/_mixins.scss';

.new-transaction-fee-info {
  @include for-desktop-and-upper {
    margin: auto;
    max-width: var(--max-form-width);
  }
  .pass-the-device-note {
    margin: -20px -16px 0 -16px;
    padding: 16px;
    font-size: 15px;
    font-weight: 400;
    border: solid 1px #FFA811;
    border-radius: 4px;
    text-align: center;
  }
  .fee-info-page__duration-title {
    margin-top: 40px;
    font-size: 18px;
    margin-bottom: 0;
  }
  .fee-info-page__duration-description {
    color: var(--secondary-text-color--light) !important;
    margin-top: 20px;
    font-size: 13px;
  }

  .new-transaction-fee-info__actions {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .new-transaction-fee-info__continue-btn {
      width: 300px;
    }
  }
}

[theme="dark"] {
  .new-transaction-fee-info {
    .fee-info-page__duration-title {
    }
    .fee-info-page__duration-description {
      color: var(--secondary-text-color--light)
    }
  }
}
