@import 'src/styles/_mixins.scss';

.barcode-modal.ant-modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
    padding: 0;
    .ant-modal-body {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      position: relative;
      @include for-desktop-and-upper {
        max-width: var(--max-form-width);
        margin: auto;
      }
      .barcode-modal__barcode-model-text {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        width: 80%;
        text-align: center;
      }
      .barcode-modal__barcode-model-cancel-btn {
        position: absolute;
        bottom: 8%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        min-width: 100px;
        margin-top: 10px;
      }
    }
  }
}
