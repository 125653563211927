@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .new-transaction-complete-page {
    .new-transaction-complete-page__content-wrapper {
      border-color: var(--box-border-color--dark);
    }
  }
}

[theme="light"] {
  .new-transaction-complete-page {
    .new-transaction-complete-page__content-wrapper {
      border-color: var(--secondary-border-color--light);
    }
  }
}

.new-transaction-complete-page {
  padding-top: 60px;

  @include for-desktop-and-upper {
    margin: auto;
    max-width: var(--max-form-width);
  }

  .new-transaction-complete-page__content-wrapper {
    margin-top: 32px;
    padding: 24px 32px;
    border-style: solid;
    border-width: 5px;
    border-radius: 4px;
  }

  .new-transaction-complete-page__title {
    text-align: center;
  }
  .new-transaction-complete-page__document-list {
    margin: 0 auto;
    max-width: 360px;
    padding-left: 25%;
    li {
      line-height: 28px;
    }
  }
  .new-transaction-complete-page__fee-wrapper {
    padding: 0 12px;
    max-width: 500px;
    margin: 16px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .fee__title {
      font-size: 20px;
      font-weight: 400;
    }
    .fee__amount {
      font-size: 25px;
      font-weight: 400;
    }
    .total-fee__title {
      font-size: 25px;
      font-weight: 400;
    }
    .total-fee__amount {
      font-size: 30px;
      font-weight: bold;
    }
  }
  .new-transaction-complete-page__actions {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 360px;

    @include for-smartphone-only {
      max-width: calc(var(--app-width) - 45%);
    }

  }
  .new-transaction-complete-page__divider {
    margin: 12px;
  }
}
