@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .account-page {
    .account-page__content {
      .account-page__user-info {
        color: #ffffff;
      }

      .transactions-tabs {
        color: var(--secondary-text-color--light);
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: var(--primary)
              }
            }
          }
        }
      }
    }
  }

  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        .account-page__dropdown-menu-item {
          color: var(--primary-text-color--light);
        }
      }
    }
  }
}
[theme="light"] {
  .account-page {
    .account-page__content {
      .account-page__user-info {
        color: var(--primary-text-color--light);
      }

      .transactions-tabs {
        color: var(--secondary-text-color--light);
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: var(--primary);
              }
            }
          }
        }
      }
    }
  }

  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      .account-page__dropdown-menu-item {
        color: var(--primary-bg-dark);
      }
    }
  }
}

.account-page {

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .account-page__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;

    .account-page__avatar {
      margin-top: 0;
    }

    .account-page__user-info {
      font-size: 18px;
      margin: 0;
    }

    .account-page__btn.ant-btn {
      margin-top: 16px;
      padding-left: 20px;
      padding-right: 20px;

      >.anticon+span {
        margin-inline-start: 20px;
      }
    }

    .account-page__profile-btn {
      margin-left: 10px;
      color: var(--primary);
    }

    .transactions-tabs.ant-tabs {
      width: 100%;
      .ant-tabs-nav {
        margin-bottom: 8px;
        &::before {
          border-bottom: none;
        }
      }
      .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
          flex-grow: 1;
          text-align: center;
          justify-content: center;
        }
        .ant-tabs-ink-bar {
          height: 4px;
          background-color: var(--primary);
        }
      }
    }
  }
}

.ant-dropdown-menu {
  min-width: 150px;
  box-shadow: none !important;
  border-radius: 4px !important;
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      .account-page__dropdown-menu-item {
        .account-page__dropdown-menu-item-text {
          font-weight: bold;
          margin-left: 12px;
        }
      }
    }
  }
}

.kyc-details-modal.ant-modal {
  .ant-modal-content {
    .kyc-details-modal__title {
      font-size: 16px;
      font-weight: 600;
    }
    table {
      tr {
        td:not(:first-child) {
          padding-left: 8px;
        }
      }
    }
  }
}
