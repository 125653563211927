@import 'src/styles/_mixins.scss';

.nada-search-modal__content {
  text-align: center;
  @include for-desktop-and-upper {
    margin: auto;
    max-width: var(--max-form-width);
  }
  .nada-search-modal__content__text {
    font-weight: bold;
  }
  .nada-search-modal__content__from {
    display: flex;
    &__nada-input {
      width: 75%;
    }  
    &__done-button {
      width: 25%;
      margin-left: 8px;
    }
  }

}

[theme="dark"] {
  .nada-search-modal__content {
    .nada-search-modal__content__hint,
    .nada-search-modal__content__hint-vehicle-info {
      color: var(--secondary-text-color--light)
    }
  }
}

[theme="light"] {
  .nada-search-modal__content {
    .nada-search-modal__content__hint,
    .nada-search-modal__content__hint-vehicle-info {
      color: var(--secondary-text-color--light)
    }
  }
}