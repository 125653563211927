.kuaay-checkbox.ant-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .ant-checkbox {
    top: 0;
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--continue);
        border-color: var(--continue);
      }
    }
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      background-color: transparent;
      border-color: var(--secondary-text-color--light);
      border-radius: 4px;

      &::after {
        inset-inline-start: 30%;
      }
    }
  }
}


[theme="dark"] {
  .kuaay-checkbox {
    color: var(--primary-text-color--dark);

    &.ant-checkbox-wrapper-disabled {
      span {
        color: var(--field-color--disabled--dark) !important;
      }
    }
  }
}

[theme="light"] {
  .kuaay-checkbox {
    color: var(--primary-text-color--light);

    &.ant-checkbox-wrapper-disabled {
      span {
        color: var(--field-color--disabled--light) !important;
      }
    }
  }
}
