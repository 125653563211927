.delete-account-modal {
  .delete-account-modal__title {
    padding: 0 16px;
  }

  &__btn-group {
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__continue {
      margin-left: 16px;
    }

    .kuaay-btn {
      width: 100px;
    }
  }
}
