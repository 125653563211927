@import 'src/styles/_mixins.scss';

.app-wrapper {
  width: 100%;
  height: auto;
  min-height: 100vh;
  box-sizing: border-box;
  --min-form-height: auto;
  @include for-desktop-and-upper {
    padding-top: 132px;
  }
}

