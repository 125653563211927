@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .transaction-pdf-modal.ant-modal {

    .ant-modal-content {

      .transaction-pdf-modal__content {

      }
    }
  }

}

[theme="light"] {
  .transaction-pdf-modal.ant-modal {

    .ant-modal-content {

      .transaction-pdf-modal__content {
      }
    }
  }

}

.transaction-pdf-modal.ant-modal {
  width: 100vw;
  height: 100vh;
  margin: 0;
  top: 0;
  left: 0;
  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
    padding: 0;

    .header-layout {
      .top-nav + div {
        @include for-mobile-device {
          padding-bottom: 32px;
        }
      }
    }

    .transaction-pdf-modal__content {
      @include for-desktop-and-upper {
        max-width: 1024px;
        margin: auto;
      }

      .react-pdf__Document {
        .react-pdf__Page {
          .react-pdf__Page__textContent {
            display: none;
          }

          .react-pdf__Page__annotations {
            display: none;
          }
        }
      }
    }
  }
}
