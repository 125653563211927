@import 'src/styles/_mixins.scss';

.known-issues-disclosure {
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .known-issues-disclosure__buyer-message {
    padding: 0 16px;
    font-style: italic;
    border-left: solid 4px var(--danger);
    white-space: pre-wrap;
  }

}

[theme="dark"] {
  .known-issues-disclosure {
    background-color: var(--primary-bg-dark);
  }
}

[theme="light"] {
  .known-issues-disclosure {
    background-color: var(--primary-bg-light);
  }

}
