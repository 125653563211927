@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .setting-item {
    border-color: var(--box-border-color--dark);
    color: #ffffff;
  }

  .settings-page__delete-account {
    border: 1px solid var(--box-border-color--dark);
  }
}

[theme="light"] {
  .setting-item {
    border-color: var(--secondary-border-color--light);
    color: #222B45;
  }

  .settings-page__delete-account {
    border-top: 1px solid var(--box-border-color--dark);
    border-bottom: 1px solid var(--box-border-color--dark);
  }
}

.setting-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  padding: 16px 24px;
  border-radius: 4px;

  .setting-item__title {
    font-size: 18px;
    font-weight: 800;
  }
  .anticon {
    svg {
      font-size: 28px;
    }
  }
}

@include for-mobile-device {
  .header-layout {
    .top-nav + div.settings-page {
      padding-bottom: 10px;
    }
  }
}

.settings-page {
  height: calc(var(--app-height) - 100px);
  @include for-desktop-and-upper {
    height: calc(var(--app-height) - 200px);
    max-width: var(--max-form-width);
    margin: auto;
  }
  .settings-page__title {
    margin-top: 30px;
    font-size: 22px;
    font-weight: 800;
    text-align: center;
  }
  .setting-item {
    margin-top: 22px;
  }
}
