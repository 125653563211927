@import 'src/styles/_mixins.scss';

.confirm-modal.ant-modal {
  .ant-modal-content {
    padding: 20px 36px;
    .ant-modal-body {
      .confirm-modal__content {
        .confirm-modal__title {
          text-align: center;
          margin-top: 0;
        }
        .confirm-modal__children {
          margin-top: 20px;
          margin-bottom: 40px;
        }
        .confirm-modal__actions {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .ant-btn {
            width: 170px;
          }
          .confirm-modal__ok-btn {
            margin-left: 16px;
          }
        }
      }
    }
  }
}
