.kuaay-select.ant-select {
  height: 40px;

  &.ant-select-open {
    .ant-select-arrow {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .ant-select-selector {
    height: 40px;
    text-align: left;

    .ant-select-selection-placeholder {
      margin-top: 3px;
    }
  }

  .ant-select-selection-item {
    line-height: 40px;
  }
}

.ant-select-dropdown {
  .ant-select-item {
    min-height: 40px;
    padding: 12px;
    .ant-select-item-option-content {
      overflow: unset;
      text-overflow: unset;
      white-space: pre-wrap;
    }
  }

}

[theme="dark"] {
  .ant-select-dropdown {
    background-color: var(--primary-bg-dark);
    border: solid 1px #101426;
    .ant-select-item {
      color: #FFFFFF;
      &.ant-select-item-option-selected {
        background-color: #374058;
      }
    }
  }
  .kuaay-select.ant-select {
    &.ant-select-open {
      .ant-select-arrow {
        svg {
          color: #FFFFFF;
        }
      }
    }

    .ant-select-selector {
      // background-color: var(--primary-input-bg--dark);
      background-color: #FDFFF5;
      border: 1px solid var(--primary);

      &:active, &:focus {
        // background-color: #222B45;
        background-color: #a3a3a2;
      }

      .ant-select-selection-item {
        // color: var(--primary-text-color--dark);
        // background-color: #a3a3a2;
      }

      .ant-select-selection-placeholder {
        // color: var(--primary-input-placeholder--dark);
        color: #8e94a3;
      }
    }

    .ant-select-arrow {
      background: transparent;
      color: #E4E9F2;
    }

    .ant-select-clear {
      background: transparent;
      color: var(--primary);
    }

    &.ant-select-disabled {
      .ant-select-selector {
        border: 1px solid var(--field-border--disabled--dark);

        .ant-select-selection-item {
          color: var(--field-color--disabled--dark);
        }

        .ant-select-selection-placeholder {
          color: var(--primary-input-placeholder--light);
        }
      }

      .ant-select-arrow {
        background: transparent;
        color: var(--primary-input-placeholder--light);
      }

      .ant-select-clear {
        background: transparent;
        color: var(--primary-input-placeholder--dark);
      }
    }
  }
}


[theme="light"] {
  .ant-select-dropdown {
    border: solid 1px #c7cbd2;
  }
  .kuaay-select.ant-select {
    &.ant-select-open {
      .ant-select-arrow {
        svg {
          color: #101426;
        }
      }
    }
    .ant-select-selector {
      background-color: var(--primary-input-bg--light);
      border: 1px solid var(--primary);

      .ant-select-selection-item {
        color: var(--primary-text-color--light);
      }

      .ant-select-selection-placeholder {
        color: var(--primary-input-placeholder--light);
      }
    }

    .ant-select-arrow {
      background: transparent;
      color: #5f6574;
    }

    .ant-select-clear {
      background: transparent;
      color: var(--primary);
    }

    &.ant-select-disabled {
      .ant-select-selector {
        border: 1px solid var(--btn-disabled-bg);

        .ant-select-selection-item {
          color: var(--field-color--disabled--light);
        }

        .ant-select-selection-placeholder {
          color: var(--primary-input-placeholder--light);
        }
      }

      .ant-select-arrow {
        background: transparent;
        color: var(--field-color--disabled--light);
      }

      .ant-select-clear {
        background: transparent;
        color: var(--primary-input-placeholder--light);
      }
    }
  }

  .ant-select-selector {
    height: 40px;
  }

  .ant-select-selection-item {
    line-height: 40px;
  }
}
