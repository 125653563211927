@import 'src/styles/_mixins.scss';

.payment-transfer-info {
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__form-switch-field {
    display: flex;

    &:hover {
      cursor: pointer;
    }

    .payment-transfer-info__form-switch-label {
      line-height: 30px;
      margin-left: 12px;
    }

    .payment-transfer-info__switch-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .payment-transfer-info__question-icon {
      margin-left: 24px;
      font-size: 18px;
    }
  }

  &__form-checkbox-field {
    display: flex;
    text-align: left;
    span.ant-typography {
      margin-left: 6px;
    }
  }

}

[theme="dark"] {
  .payment-transfer-info {
    background-color: var(--primary-bg-dark);

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--dark)
      }
    }

    .payment-transfer-info__question-icon {
      color: var(--primary-text-color--dark);
    }
  }
}

[theme="light"] {
  .payment-transfer-info {
    background-color: var(--primary-bg-light);

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--light)
      }
    }

    .payment-transfer-info__question-icon {
      color: var(--primary-text-color--light);
    }
  }
}

.payment-protection-modal.ant-modal {
  .ant-modal-content {
    padding: 20px 36px;
    border-radius: 4px;

    .ant-modal-body {
      .payment-protection-modal__actions {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
