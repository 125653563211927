[theme="dark"] .sign-up-page {
  .sign-up-page__title {
    color: #ffffff;
  }
  .sign-in-link {
    color: #ffffff;
  }
}

[theme="light"] .sign-up-page {
  .sign-up-page__title {
    color: #000000;
  }
  .sign-in-link {
    color: #222B45;
  }
}

.sign-up-page {
  padding-bottom: 72px;
  .sign-up-page__title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-top: 50px;
  }
  .sign-up-page__subtitle{
    font-size: 17px;
    font-weight: 200;
    text-align: center;
  }
  .sign-up-form {
    margin: 32px auto 0 auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .sign-up-button-wrapper {
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }
    }
    .sign-up-button.ant-btn {
      margin-top: 16px;
      width: 225px;
      >.anticon+span {
        margin-left: 24px;
      }
    }
  }
  .sign-in-link {
    font-size: 17px;
    font-weight: 200;
    text-align: center;
    display: block;
    margin: 16px auto 0 auto;
  }
}
