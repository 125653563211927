@import 'src/styles/_mixins.scss';

.top-bar {
  background-color: #000; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;  

  .top-bar__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 10px;
    margin: auto;
    width: 100%;
    padding: 0 20px; 
    height: 400px; 
    padding: 0;
    box-sizing: border-box;
    
    .top-bar__background {
      display: flex;
      align-items: center;
      position: relative;
      width: 50%; 
      height: 100%; 

      .top-bar__background-image {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover; 
        z-index: 0;
        opacity: 0.8; 
      }

      .top-bar__logo {
        position: relative;
        z-index: 1; 
        padding-bottom: 13px;
        margin-left: 16px;  
        margin-top: 16px;
        img {
          height: 30px;
          width: auto;
        }
      }
    }

    .top-bar__header__items {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      height: 100%;
      color: #fff;

      .top-bar__account-icon {
        margin-right: 20px; 
        font-size: 24px;
        cursor: pointer;
      }
  
            
    .top-bar__menu {
        background-color: transparent;
        border: none;
      }
    }
  }
}

.top-bar__user-icon {
  position: absolute;
  right: 20px; 
  display: flex;
  align-items: center;

  .top-bar__icon-image {
    width: 40px; 
    height: 40px; 
    border-radius: 50%; 
    object-fit: cover; 
    cursor: pointer;
  }
}

[theme="light"] {
  .top-bar__logo-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .imageIcon {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover; 
    opacity: 0.5;
  }

  .top-bar__logo {
    z-index: 1; 
    margin-right: 16px; 
  }
}

[theme="light"] {
  .imageIcon {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    object-fit: contain;
    z-index: 1;
  }
}

.top-bar {
  position: top;
  top: 0;
  left: 0;
  width: 100%;
  .top-bar__content {
    width: 100%;
    max-width: 100%;
    height: 140px;
    margin: auto;
    padding: 0 0px;
    z-index: 1000;
    box-sizing: border-box;
    @include for-mobile-device() {
      display: none !important;
    }
    @include for-lg-desktop() {
      max-width: 100%;
    }

    .top-bar__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0 16px 0px;
      .top-bar__logo {
        img {
          height: 3rem;
          margin-top: 1rem;
        }
      }
      .top-bar__header__items {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .ant-btn {
          font-weight: 400;
        }
        .top-bar__menu {
            background-color: transparent;
            border: none;
            font-size: 1.75rem;
            .anticon {
              font-size: 40px;
            }
        }
      }
      
    }


    .navigation.top-bar__navigation {
      height: 3rem;
      justify-content: flex-start;
      .navigation-item {
        flex-grow: 0;
        min-width: 148px;
        justify-content: center;
        .navigation-item__title {
          font-size: 1rem;
        }
        .nav-item__icon-container {
          display: none;
        }
      }
      .alert-icon {
        svg {
          color: red !important;
          font-size: 18px;
        }
      }
    }
  }
}

.side-drawer__header {
      display: flex;
      align-items: center;
      padding: 16px;
      background-color: #1B1B1B;
  
      .side-drawer__logo {
        img {
          height: 30px;
          width: auto;
        }
      }
}

.side-drawer  {
  background-color: #1b1b1b; /* Set background color of the drawer */
  height: 100%; /* Make the drawer take full height */

}

.side-drawer__menu {
  background-color: #1b1b1b; /* Set background color of the menu */
}

.side-drawer .ant-drawer-content {
  background-color: #1b1b1b !important; /* Apply background to the drawer content */
}

.side-drawer .ant-drawer-body {
  padding: 0; /* Remove padding around the drawer content */
  background-color: #1b1b1b; /* Set background color of the drawer body */
}
.ant-drawer-header {
  background-color: #1B1B1B !important;
}

.side-drawer__menu .ant-menu-item,
.side-drawer__menu .ant-menu-submenu-title {
  color: white !important; /* Set text color to white */
}

.side-drawer__menu .ant-menu-item:hover,
.side-drawer__menu .ant-menu-submenu-title:hover {
  color: #87d068 !important; /* Optional: change text color on hover */
}

.side-drawer__menu .ant-menu-item-selected {
  background-color: #333333 !important; /* Optional: change selected item background */
  color: #87d068 !important; /* Optional: change selected item text color */
}

.anticon{
  color: white !important
}