[theme="dark"] {
  .ant-dropdown-menu {
    background-color: var(--primary-bg-light);
    border: solid 1px #151A30;
    .ant-dropdown-menu-item-divider {
      background-color: #151A30;
    }
    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        .new-transaction__dropdown-menu-item {
          color: var(--primary-text-color--light);
          .new-transaction__dropdown-menu-item-icon {
            color: var(--primary-input-placeholder--dark);
          }
        }
      }
    }
  }
}

[theme="light"] {
  .ant-dropdown-menu {
    background-color: var(--primary-bg-light);
    border: solid 1px #EDF1F7;
    .ant-dropdown-menu-item-divider {
      background-color: #EDF1F7;
    }
  }
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      .new-transaction__dropdown-menu-item {
        color: var(--primary-bg-dark);
        .new-transaction__dropdown-menu-item-icon {
          color: var(--primary-input-placeholder--light);
        }
      }
    }
  }
}

.ant-dropdown-menu {
  min-width: 150px;
  box-shadow: none !important;
  border-radius: 4px !important;
  .ant-dropdown-menu-item-divider {
    margin-left: -4px;
    margin-right: -4px;
  }
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      .new-transaction__dropdown-menu-item {
        .new-transaction__dropdown-menu-item-text {
          margin-left: 12px;
        }
      }
    }
  }
}
