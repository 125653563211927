@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .about-item {
    border-color: #101426;
    color: #ffffff;
  }
}

[theme="light"] {
  .about-item {
    border-color: var(--secondary-border-color--light);
    color: #222B45;
  }
}

.about-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  padding: 16px 24px;
  margin-top: 16px;
  border-radius: 4px;
  cursor: pointer;

  .about-item__title {
    font-size: 18px;
    font-weight: 800;
  }
  .anticon {
    svg {
      font-size: 28px;
    }
  }
}

.about-modal.ant-modal, .private-policy-modal.ant-modal, .terms-and-conditions-modal.ant-modal {
  width: 100vw;
  height: 100vh;
  margin: 0;
  top: 0;
  left: 0;
  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
    padding: 0;
    .about-modal__content {

      @include for-desktop-and-upper {
        max-width: var(--max-form-width);
        margin: auto;
      }
    }
    .about-modal__content, .private-policy-modal__content, .terms-and-conditions-modal__content {
      .kuaay-logo {
        display: flex;
        width: 100%;
        justify-content: center;
        img {
          display: block;
          height: 80px;
        }
      }
      .kuaay-description {
        margin-top: 55px;
        text-align: center;
      }
    }
  }
}


.private-policy-modal {
  .private-policy-modal__content {
    .iframe-container {
      margin: 12px -12px 0 -12px;
    }
    iframe#private-policy {
      border: none;
      width: 100%;
      box-sizing: border-box;
      height: calc(100vh - 200px);
    }
  }
}

.terms-and-conditions-modal {
  .header-layout {
    .top-nav + div {
      @include for-mobile-device {
        padding-bottom: 32px;
      }
    }
  }
  .terms-and-conditions-modal__content {
    .iframe-container {
      margin: 12px -12px 0 -12px;
    }
    iframe#terms-and-conditions {
      border: none;
      width: 100%;
      box-sizing: border-box;
      height: calc(100vh - 200px);
    }
  }
}
