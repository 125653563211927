@import 'src/styles/_mixins.scss';

.new-transaction-signature-odometer {
  text-align: center;
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__title {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  &__continue-button {
    width: 100%;
    margin-bottom: 30px;
  }
}

[theme="dark"] {
  .new-transaction-signature-odometer {
    background-color: var(--primary-bg-dark);

    .new-transaction-signature-odometer__title {
      color: var(--primary-text-color--dark);
    }
  }
}

[theme="light"] {
  .new-transaction-signature-odometer {
    background-color: var(--primary-bg-light);

    .new-transaction-signature-odometer__title {
      color: var(--primary-text-color--light);
    }
  }
}
