@import 'src/styles/_mixins.scss';

.payment-protection-disclosure {
  text-align: center;
  padding-bottom: 20px;
  min-height: 100%;
  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__content {
    font-size: 15px;
    text-align: left;
  }

  &__continue-button {
    width: 100%;
    margin-bottom: 24px;
  }
}

[theme="dark"] {
  .payment-protection-disclosure {
    background-color: var(--primary-bg-dark);
  }
}

[theme="light"] {
  .payment-protection-disclosure {
    background-color: var(--primary-bg-light);
  }
}
