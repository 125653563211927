
[theme="dark"] {
  .kuaay-switch.ant-switch {
    border: solid 1px #8c94aa;
    background: #2e374c;
    &.ant-switch-checked {
      border: solid 1px var(--primary);
      background: var(--primary);
      .ant-switch-handle {
        &::before {
          background-color: var(--primary-bg-dark);
        }

      }
    }
    .ant-switch-handle {
      &::before {
        background-color: #232c45;
      }

    }
  }
}

[theme="light"] {
  .kuaay-switch.ant-switch {
    border: solid 1px var(--secondary-text-color--light);
    background-color: rgba(143, 155, 179, 0.08);

    &.ant-switch-checked {
      border: solid 1px var(--primary);
      background: var(--primary);
      .ant-switch-handle {
        &::before {
          background-color: #FFFFFF;
        }

      }
    }

    .ant-switch-handle {
      &::before {
        background-color: #ffffff;
      }

    }
  }
}

.kuaay-switch.ant-switch {
  height: 30px;
  min-width: 48px;
  box-sizing: border-box;

  &.ant-switch-checked {
    .ant-switch-handle {
      inset-inline-start: calc(100% - 26px);

      &::after {
        content: " ";
        width: 10px;
        height: 6px;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 8px;
        margin-left: 8px;
        transform: rotate(-45deg);
        border-bottom: solid 3px var(--primary);
        border-left: solid 3px var(--primary);
      }
    }
  }

  .ant-switch-handle {
    width: 24px;
    height: 24px;

    &::before {
      border-radius: 12px;
    }
  }
}
