.feature-card {
    background-color: grey;
    color: white;
    border-radius: 10px; // adjust this value to your desired radius
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px; // adjust this value to your desired padding
    width: 93%; 
    white-space: nowrap; // keep the text in one line
    overflow: visible;
    text-overflow: ellipsis; // show ellipsis when the text is too long
    margin-top: 5px;
    position: relative;
    z-index: 0;
    border: 1px solid white;
    height: auto;
   }

  .feature-card.green {
    background-color: green;
  }
  
  .feature-card__text {
    border: 1px solid transparent; // Prevent invisible borders
    background: transparent;
    resize: none;
    color: white;
    height: 57px;
    min-height: 57px; // Ensures consistent dimensions
    flex-grow: 1;
    pointer-events: auto;
    touch-action: manipulation;
    user-select: text;
    position: relative;
    z-index: 1; // Prevent overlap issues
    &:focus {
      border: 1px solid white; // Visual focus indicator
      background: rgba(255, 255, 255, 0.1);
    }
  }
  
  .feature-card__text::placeholder {
    font-style: italic;
    color: white;
  }

  .feature-card__buttons {
    display: flex;
    flex-direction: row; // Align buttons in a single line
    align-items: center; // Center buttons vertically
    position: relative;
    z-index: 1;
  }
  
  .feature-card__button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-right: 0px;
  }

  .feature-card__button:last-child {
    margin-right: 0; // Remove the margin from the last button
  }