@import 'src/styles/_mixins.scss';

.shipment-rate-card {
  cursor: pointer;
  border-color: #a3a3a3;

  .ant-card-body {
    padding: 18px 8px;
  }

  &.shipment-rate-card-active {
    border-color: var(--primary)!important;
    background-color: #F7F9FC;
    border-width: 2px;

    .shipment-rate-card-body {
      .shipment-rate-card-body__title {
        color: var(--primary);
      }

      .shipment-rate-card-body__sub-title {
        color: var(--primary);
      }

      svg.shipment-rate-card-body__icon {
        color: var(--primary);
      }

    }
  }

  .shipment-rate-card-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .shipment-rate-card-body__text {
      margin-left: 18px;
      .shipment-rate-card-body__title {
        font-weight: 500;
        font-size: 12px;
      }
      .shipment-rate-card-body__sub-title {
        font-weight: 300;
        font-size: 10px;
      }
    }
    .shipment-rate-card-body__fee {
      margin-left: 18px;
      padding: 8px 12px;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      background-color: #cccccc;
      border: solid 1px #a3a3a3;
    }
  }
}

[theme="dark"] {
  .shipment-rate-card {
    background-color: var(--primary-bg-dark);

    svg.shipment-rate-card-body__icon {
      color: var(--primary-text-color--dark);
    }
  }
}
