@import 'src/styles/_mixins.scss';

.main-verification-container{
  text-align: center;
  padding-bottom: 20px;
  max-height: 85% !important;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__title {
    font-size: 26px;
    font-weight: 800;
  }

  &__form-checkbox-field {
    display: flex;
    text-align: left;
    span.ant-typography {
      margin-left: 6px;
    }
  }

}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; 
  
  .transaction-form__btn {
    width: 100%; 
  }
}


[theme="dark"] {
  .main-vehicle-info {
    background-color: var(--primary-bg-dark);
    .main-vehicle-info__title {
      color: var(--primary-text-color--dark);
    }

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--dark)
      }
    }
  }
}

[theme="light"] {
  .main-vehicle-info {
    background-color: var(--primary-bg-light);
    .main-vehicle-info__title {
      color: var(--primary-text-color--light);
    }

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--light)
      }
    }
  }
}


.id-ver_button {
  padding-bottom: 10px;
  margin-top: 24px;
  margin-bottom: 0;
  width: 260px;
  font-size: 26px;
  font-weight: 800;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ver-list_header {
  font-size: 'smaller';
  border-bottom: '1px solid white';
  margin-bottom: 5px;
}

.ver-list_status_box {
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 5px;
  margin-bottom: 3px;
  margin-top: 3px;
}

.ver-list_status_box.verified {
  background-color: green;
}

.ver-list_status_box.unverified {
  background-color: red;
}

.id_container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;

  img {
    max-height: 200px;
    width: calc(50% - 10px); // Subtract the margin from the width
    object-fit: cover;
    margin: 5px; // Add a margin to the images
    border: 1px solid #ccc;
  }
}

.notif-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; // Add space between rows
}

.notif-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; // Add space between title and icon
}

.notif-row {
  display: flex;
  justify-content: flex-start; // Align items to the left
  align-items: flex-start; // Align items to the top
  gap: 3px; // Add space between items
}

.verification-details {
  background-color: #2c2c2c; // Set the background color
  border-radius: 10px; // Round the corners
  padding: 10px 5px; // Add some padding
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); // Add a subtle shadow
  overflow-y: auto; // Add a scrollbar if the content exceeds the max height

  .header {
    color: grey !important;; // Set the color of the header
    text-align: center; // Center the header
  }
}

.view_details_btn {
  width: 80%;
}