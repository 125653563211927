@import 'src/styles/_mixins.scss';

.needed-info {
  text-align: center;
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .pass-the-device-note {
    margin: -20px -20px 0 -20px;
    padding: 16px 8px;
    font-size: 15px;
    font-weight: 400;
    border: solid 1px #FFA811;
    border-radius: 4px;
  }

  &__title {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .other-info {
    margin-top: 28px;
    font-size: 15px;
    &__main-text {
      font-weight: 400;
      text-align: left;
    }
    &__check-list {
      font-weight: 400;
      padding-inline-start: 16px;
      text-align: left;
      list-style-type: decimal;

      li {
        padding-left: 16px;
      }
    }
  }

  &__continue-button {
    width: 100%;
    margin-bottom: 24px;
  }
}

[theme="dark"] {
  .needed-info {
    background-color: var(--primary-bg-dark);

    .needed-info__title {
      color: var(--primary-text-color--dark);
    }
  }
}

[theme="light"] {
  .needed-info {
    background-color: var(--primary-bg-light);

    .needed-info__title {
      color: var(--primary-text-color--light);
    }
  }
}
