@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .shipment-card {
    color: #ffffff;
    background-color: var(--primary-bg-dark);
    border-color: var(--box-border-color--dark);
    * {
      color: #ffffff;
    }

    &[data-is-focus="true"] {
      background-color: #1c243d;
    }

    .ant-card-head {
      border-bottom-color: var(--box-border-color--dark);
    }

    .ant-card-body {
      .shipment-card__status-container {
        .shipment-card__status-container__status {
          color: var(--secondary-text-color--light);
        }
      }
    }
  }
}

[theme="light"] {
  .shipment-card {
    color: var(--primary-text-color--light);
    background-color: #ffffff;
    border-color: var(--box-border-color--light);
    * {
      color: var(--primary-text-color--light);
    }
    &[data-is-focus="true"] {
      background-color: rgb(244, 245, 248);
    }

    .ant-card-head {
      border-bottom-color: var(--box-border-color--light);
    }

    .ant-card-body {
      .shipment-card__status-container {
        .shipment-card__status-container__status {
          color: var(--secondary-text-color--light);
        }
      }
    }
  }
}

.shipment-card.ant-card {
  width: 100%;
  border-radius: 4px;
  --shipment-border-top-color: #44A8FF;
  cursor: pointer;

  &.shipment-card {
    &--primary {
      --shipment-border-top-color: #3366FF;
    }
    &--success {
      --shipment-border-top-color: #4CE051;
    }
    &--warning {
      --shipment-border-top-color: #FFA811;
    }
    &--info {
      --shipment-border-top-color: #44A8FF;
    }
  }

  .ant-card-head {
    border-radius: 4px 4px 0 0 ;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: var(--shipment-border-top-color);
    padding: 16px 24px;
    .shipment-card__title {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include for-mobile-device {
        flex-direction: column;
      }

      .shipment-card__title__vehicle-list {
        padding-inline-start: 20px;
        margin: 0;
        .shipment-card__vehicle-info-container {
          &:not(:first-child) {
            margin-top: 8px;
          }
          display: flex;
          flex-direction: row;
          @include for-smartphone-only {
            flex-direction: column;
          }

        }
      }
      .shipment-card__title__shipping-cost {
        @include for-mobile-device {
          margin-top: 8px;
        }
      }
    }

    .shipment-card__subtitle {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .ant-card-body {
    padding: 16px 24px;

    .shipment-card__pending-detail-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .shipment-card__qr-icon {
        svg {
          font-size: 48px;
        }
      }
    }

    .shipment-card__price {
      font-size: 15px;
      font-weight: 600;
    }

    .shipment-card__status-container {
      font-size: 12px;
      font-weight: 600;
      .shipment-card__status-container__status {
        font-size: 14px;
      }
    }
  }

}
