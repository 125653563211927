@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .transaction-detail-page {}
}

[theme="light"] {
  .transaction-detail-page {}
}

.transaction-detail-page {
  padding-top: 24px;
  padding-bottom: 100px;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }
}

.email-pdf-modal {
  .ant-modal-content {
    .email-pdf-modal__title {
      font-size: 15px;
      font-weight: 400;
    }
    .email-pdf-modal__form {
      margin-top: 16px;
      .email-pdf-modal__form-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
