@import 'src/styles/_mixins.scss';

.barcode-scanner {
  width: 100%;
  height: 100%;
  align-items: center;
  //video {
  //  object-fit: cover;
  //}
  .barcode-scanner__info-text {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 80%;
    text-align: center;
  }
  .barcode-scanner__overlay-wrapper {
    justify-content: center;
    align-items: center;
    height: 100%;
    .barcode-scanner__overlay-top-left {
      height: 30px;
      width: 11%;
      right: 85%;
      top: 38%;
      background-color: transparent;
      position: absolute;
      border-left: solid 3px white;
      border-top: solid 3px white;
      z-index: 99;
      opacity: 0.9;
    }
    .barcode-scanner__overlay-bottom-left {
      height: 30px;
      width: 11%;
      right: 85%;
      bottom: 38%;
      background-color: transparent;
      position: absolute;
      border-left: solid 3px white;
      border-bottom: solid 3px white;
      z-index: 99;
      opacity: 0.9;
    }
    .barcode-scanner__overlay-top-right {
      height: 30px;
      width: 11%;
      right: 3%;
      top: 38%;
      background-color: transparent;
      position: absolute;
      border-right: solid 3px white;
      border-top: solid 3px white;
      z-index: 99;
      opacity: 0.9;
    }
    .barcode-scanner__overlay-bottom-right {
      height: 30px;
      width: 11%;
      right: 3%;
      bottom: 38%;
      background-color: transparent;
      position: absolute;
      border-right: solid 3px white;
      border-bottom: solid 3px white;
      z-index: 99;
      opacity: 0.9;
    }
  }
}
