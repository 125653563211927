[theme="dark"] {
  .no-transaction-card {
    color: #ffffff;
    background-color: var(--primary-bg-dark);
    border-color: var(--box-border-color--dark);
    * {
      color: #ffffff;
    }
    &[data-is-focus="true"] {
      background-color: #1c243d;
    }
  }
}

[theme="light"] {
  .no-transaction-card {
    color: var(--primary-text-color--light);
    background-color: #ffffff;
    border-color: var(--box-border-color--light);
    * {
      color: var(--primary-text-color--light);
    }
    &[data-is-focus="true"] {
      background-color: rgb(244, 245, 248);
    }


  }
}

.no-transaction-card.ant-card {
  width: 100%;
  border-radius: 4px;
  cursor: pointer;

  .ant-card-head {
    border-radius: 4px 4px 0 0 ;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: var(--danger);
    padding: 64px 24px 0 24px;
    border-bottom-color: transparent;
    text-align: center;
    font-size: 22px;
    font-weight: 800;
  }

  .ant-card-body {
    padding: 0 24px 64px 24px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
  }

}
