[theme="light"] {
  .transaction-qr-modal.k-modal {
    .transaction-qr-modal__content {
      .transaction-qr-modal__link {
        color: blue;
      }
    }
  }
}

.transaction-qr-modal.k-modal {
  .transaction-qr-modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    .transaction-qr-modal__description {
      text-align: center;
      padding: 10px 0;
    }
    .transaction-qr-modal__title {
      text-align: center;
      padding: 10px 0;
    }
    .transaction-qr-modal__qr-code {
      margin-top: 20px;
      padding: 8px;
      background-color: #FFFFFF;
    }
    .transaction-qr-modal__link {
      color: green;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
    }
    .transaction-qr-modal__continue-btn {
      margin-top: 20px;
      width: 300px;
    }
  }
}
