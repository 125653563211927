@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .shipment-detail {
    .shipment-detail__section {
      .shipment-detail__section__label {
        color: var(--secondary-text-color--light);
      }
      .shipment-detail__section__content {
        a {
          color: green;
        }
      }
    }
  }

  .shipment-modification-modal {
    .shipment-modification-modal__label {
      color: var(--primary-text-color--dark);
    }
  }
}

[theme="light"] {
  .shipment-detail {
    .shipment-detail__section {
      .shipment-detail__section__label {
        color: var(--secondary-text-color--light);
      }
      .shipment-detail__section__content {
        a {
          color: blue;
        }
      }
    }
  }

  .shipment-modification-modal {
    .shipment-modification-modal__label {
      color: var(--primary-text-color--light);
    }
  }
}

.shipment-detail {
  padding-top: 24px;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .shipment-detail__label {
    font-size: 26px;
    font-weight: 800;
    text-align: left;
  }

  .shipment-detail__section {
    margin-top: 20px;
    &.shipment-detail__actions-section {
      margin-top: 32px;
      display: flex;
      padding-bottom: 20px;
      flex-direction: column;
      align-items: center;
      .shipment-detail__actions__btn {
        margin-top: 16px;
        width: 400px;
        @include for-smartphone-only {
          width: 100%;
        }
      }
    }
    .shipment-detail__section__label {
      font-size: 15px;
      font-weight: bold;
    }
    .shipment-detail__section__content {
      margin-top: 4px;
      font-size: 22px;
      line-height: 30px;
      font-weight: 800;
      overflow-wrap: anywhere;
      .shipment-detail__vehicle-list {
        padding-inline-start: 24px;
        margin: 0;
        .shipment-card__vehicle-info-item {
          &:not(:first-child) {
            margin-top: 10px;
          }
          .shipment-card__vehicle-info-container {

            display: flex;
            flex-direction: row;
            @include for-smartphone-only {
              flex-direction: column;
            }

          }
        }
      }
    }
  }
}



.shipment-modification-modal {
  .shipment-modification-modal__label {
  }
  .shipment-modification-modal__form-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .ant-btn {
      width: 120px;
    }
  }
}

