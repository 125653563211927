[theme="dark"] .sign-in-page {
  .sign-in-page__title {
    color: #ffffff;
  }
  .sign-up-link {
    color: #ffffff;
  }
}

[theme="light"] .sign-in-page {
  .sign-in-page__title {
    color: #000000;
  }
  .sign-up-link {
    color: #222B45;
  }
}

.sign-in-page {
  padding-bottom: 72px;
  .sign-in-page__title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-top: 100px;
  }
  .sign-in-page__subtitle{
    font-size: 17px;
    font-weight: 200;
    text-align: center;
    margin-top: 16px;
  }
  .sign-in-form {
    margin: 32px auto 0 auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .sign-in-button-wrapper {
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }
    }
    .sign-in-button.kuaay-btn {
      margin-top: 16px;
      width: 225px;
      >.anticon+span {
        margin-left: 24px;
      }
    }
  }
  .sign-up-link {
    font-size: 17px;
    font-weight: 200;
    text-align: center;
    display: block;
    margin: 16px auto 0 auto;
  }
}
