@import 'src/styles/_mixins.scss';

.new-transaction-signature {
  @include for-desktop-and-upper {
    margin: auto;
    max-width: var(--max-form-width);
  }
  .new-transaction-signature__main-section {
    padding-bottom: 20px;
    .new-transaction-signature__title {
      font-size: 26px;
      font-weight: 800;
      text-align: center;
    }
    .new-transaction-signature__description {
      padding: 10px 0 30px 0;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
    }
  }
  .signature-pad {
    width: 100%;
    height: calc(var(--app-height) / 2.5);
    background-color: #ffffff;
    border-top: solid 2px grey;
    border-bottom: solid 2px grey;
  }
  .new-transaction-signature__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    .kuaay-btn {
      width: 80%;
      @include for-tablet-and-up {
        width: 400px;
      }
      &.new-transaction-signature__actions__clear-btn {
        margin-top: 24px
      }
    }
  }
}
