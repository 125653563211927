@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .header-layout {
    background-color: var(--primary-bg-dark);
    color: #FFFFFF;
    width: 100%;
    padding-top: 10px;
    .top-nav {
      background-color: var(--primary-bg-dark);
      border-bottom-color: #151A30;
      .top-nav__title-wrapper {
        .top-nav__title {
          color: #ffffff;
        }
        .top-nav__subtitle {
          color: var(--primary-text-color--dark);
        }
      }
    }
  }
}

[theme="light"] {
  .header-layout {
    background-color: var(--primary-bg-light);
    color: var(--primary-text-color--light);
    padding-top: 10px;
    .top-nav {
      background-color: var(--primary-bg-light);
      border-bottom-color: #EDF1F7;
      .top-nav__title-wrapper {
        .top-nav__title {
          color: var(--primary-text-color--light);
        }
        .top-nav__subtitle {
          color: var(--secondary-text-color--light);
        }
      }
    }
  }
}

.header-layout {
  @include for-desktop-only {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 100%;
  }
  @include for-lg-desktop {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1280px;
  }
  .top-nav {
    position: sticky;
    z-index: 100;
    top: 0;
    left: 0;
    height: 56px;
    width: 100%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    box-sizing: border-box;
    max-width: 100%;
    @include for-desktop-and-upper {
      position: unset;
    }

    + div {
      padding-top: 20px;
    }

    .anticon {
      padding: 2px;
      svg {
        font-size: 18px;
      }
    }
    .top-nav__left-slot {
      display: flex;
      flex: 1;
      justify-content: flex-start;
    }
    .top-nav__right-slot {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      .anticon {
        margin-left: 12px;
      }
    }
    .top-nav__title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .top-nav__title {
        line-height: 24px;
        font-size: 15px;
        font-weight: 600;
      }

      .top-nav__subtitle {
        line-height: 16px;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .top-nav + div {
    padding-bottom: 24px;

    // @include for-mobile-device {
    //   padding-bottom: 110px;
      
    // }
  }
}
