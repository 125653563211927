@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .vehicle-form {
    .vehicle-form__select {

    }
  }
}

[theme="light"] {
  .vehicle-form {
    .vehicle-form__select {
      &.ant-select-open {
        .ant-select-arrow {
          svg {
            color: #101426;
          }
        }
      }
      .ant-select-selector {
        background-color: var(--primary-input-bg--light);
        border: 1px solid var(--primary);

        .ant-select-selection-item {
          color: var(--primary-text-color--light);
        }

        .ant-select-selection-placeholder {
          color: var(--primary-input-placeholder--light);
        }
      }

      .ant-select-arrow {
        background: transparent;
        color: #5f6574;
      }

      .ant-select-clear {
        background: transparent;
        color: var(--secondary-text-color--light);
      }

      &.ant-select-disabled {
        .ant-select-selector {
          border: 1px solid var(--btn-disabled-bg);

          .ant-select-selection-item {
            color: var(--field-color--disabled--light);
          }

          .ant-select-selection-placeholder {
            color: var(--primary-input-placeholder--light);
          }
        }

        .ant-select-arrow {
          background: transparent;
          color: var(--field-color--disabled--light);
        }

        .ant-select-clear {
          background: transparent;
          color: var(--primary-input-placeholder--light);
        }
      }
    }
  }
}

.vehicle-form {
  .vehicle-form__select.ant-select {
    height: 40px;
    &.ant-select-open {
      .ant-select-arrow {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .ant-select-selector {
      height: 40px;
      text-align: left;

      .ant-select-selection-placeholder {
        margin-top: 3px;
      }
    }

    .ant-select-selection-item {
      line-height: 40px;
    }

    .ant-select-clear {
      position: absolute;
      right: 10px;
      top: 16px;
      width: 20px;
      height: 20px;
      svg {
        font-size: 20px;
      }
    }
  }
}
