@import 'src/styles/_mixins.scss';

.shipment-quote-page {
  padding-top: 24px;
  padding-bottom: 100px;
  .kuaay-input.ant-input-affix-wrapper {
    color: black; // Override the text color for this form only

    // Optional: Adjust other text-related styles if necessary
    ::placeholder {
      color: darkgray; // Placeholder color customization (if needed)
    }
  }
 
  @include for-desktop-and-upper {
    // max-width: var(--max-form-width)
    margin: auto;
  }

  &__form-wrapper {
    display: flex;
    justify-content: center; // Centers the form horizontally
    align-items: center; // Centers the form vertically

    .shipment-quote-page__form {
      max-width: 600px; // Adjust the maximum width for better centering
      width: 180%; // Ensure the form takes full width of the container
      padding-top: 90px;

      .shipment-page-quote__form__car-info {
        .shipment-page-quote__shipment-card {
          margin-bottom: 18px;
          align-items: center;
          justify-content: center;

          .shipment-page-quote__shipment-card-left {
            padding-right: 12px;
          }

          .shipment-page-quote__shipment-card-right {
            padding-left: 12px;
          }
        }

        .shipment-page-quote__form__car-info-value {
          font-weight: 600;
        }
      }

      .shipment-page-quote__form__zip-input {
        width: 100%; // Make zip input fields full width
      }

      .ant-input,
      .ant-picker {
        width: 100%; // Set all input fields to full width
      }

      .shipment-page-quote__form__switch {
        .shipment-page-quote__form__switch-label {
          margin-left: 12px;
        }
      }
    }
  }

  .kuaay-btn.ant-btn {
    border-radius: 4px;
    font-weight: bold;
    min-height: 40px;
    font-size: 14px;
    min-width: 80px;
  
    @media (max-width: 800px) {
      width: 100px; // Set button width to 100px for smaller screens
    }
  }

  @include for-smartphone-only {
    .shipment-quote-page__form {
      max-width: 600px; // Adjust the maximum width for better centering
      width: 100%; // Ensure the form takes full width of the container
      padding-top: 10px;
    }
  }

  &__btn {
    width: 100%; // Make the button width responsive
    max-width: 400px; // Limit the button width on larger screens

    @include for-smartphone-only {
      width: 80%;
    }
  }
}
