@import 'src/styles/_mixins';

[theme="light"] {

  .transaction-basic-detail {
    .transaction-basic-detail__section {
      .transaction-basic-detail__section__label {
        color: var(--secondary-text-color--light);
      }
      .transaction-basic-detail__delivery-section__value {
        a {
          color: blue;
        }
      }
    }
  }
}

[theme="dark"] {
  .transaction-basic-detail {
    .transaction-basic-detail__section {
      .transaction-basic-detail__section__label {
        color: var(--secondary-text-color--light);
      }
      .transaction-basic-detail__delivery-section__value {
        a {
          color: green;
        }
      }
    }
  }
}

.transaction-basic-detail {
  .transaction-basic-detail__title {
    font-size: 26px;
    font-weight: 800;
    text-align: left;
  }
  .transaction-basic-detail__role {
    font-size: 18px;
    font-weight: 800;
    text-align: left;
  }
  .transaction-basic-detail__date {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
  }

  .transaction-basic-detail__section {
    margin-top: 20px;
    .transaction-basic-detail__section__label {
      font-size: 15px;
      font-weight: bold;
    }
    .transaction-basic-detail__section__value {
      font-size: 22px;
      font-weight: 800;
    }
    .transaction-basic-detail__delivery-section__value {
      font-size: 18px;
      font-weight: 800;
    }
  }
}
