.kuaay-input.ant-input-affix-wrapper {
  padding: 0;
  position: relative;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  min-height: 40px;
  border-radius: 4px;
  font-size: 15px;

  &.kuaay-input--has-prefix {
    input {
      padding-left: 28px;
    }
  }

  &.kuaay-input--has-suffix {
    input {
      padding-right: 32px;
    }
  }

  .ant-input-suffix {
    position: absolute;
    right: 8px;
    top: 0;
    min-height: 40px;
    svg {
      font-size: 20px;
    }
  }
  .ant-input-prefix {
    position: absolute;
    left: 8px;
    top: 0;
    min-height: 40px;
    z-index: 1;
    svg {
      font-size: 20px;
    }
    color: #222B45;
  }
  input {
    padding: 4px 11px 4px 11px;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    min-height: 40px;
  }
}

[theme="dark"] {
  .kuaay-input.ant-input-affix-wrapper {
    border-color: var(--primary);
    color: #FDFFF5;

    &.kuaay-input-v2 {
      &:not(.ant-input-affix-wrapper-focused):not(.ant-input-affix-wrapper-status-error) {
        border-color: var(--box-border-color--dark);

      }
      &.ant-input-affix-wrapper-focused {

      }

    }

    &.ant-input-affix-wrapper-disabled {
      border-color: var(--box-border-color--dark) !important;

      input {
        color: var(--field-color--disabled--dark) !important;

        &::-webkit-input-placeholder {
          color: rgba(143, 155, 179, 0.48);
        }

        &::placeholder {
          color: rgba(143, 155, 179, 0.48);
        }
      }

      .ant-input-suffix {
        color: var(--field-color--disabled--light);
      }
    }

    &.ant-input-affix-wrapper-focused {
      background-color: var(--primary-input-bg-dark-focused);
      .anticon {
        svg {
          color: var(--primary);
        }
      }

    }
    input {
      border: none;
      // background-color:var(--primary-input-bg--dark);
      background-color: #FDFFF5;
      color: #2a2a2a;
      &:active, &:focus {
        // background-color: var(--primary-input-bg-dark-focused);
        background-color: #a3a3a2;

      }
      &::-webkit-input-placeholder {
        color: #8e94a3;
      }

      &::placeholder {
        color: #8e94a3;
      }
    }

    .anticon {
      svg {
        color: var(--primary);
      }
    }

  }
}

[theme="light"] {
  .kuaay-input.ant-input-affix-wrapper {
    border-color: var(--primary);
    color: #222B45;

    &.kuaay-input-v2 {
      &:not(.ant-input-affix-wrapper-focused) {
        border-color: var(--box-border-color--light);

      }
      &.ant-input-affix-wrapper-focused {

      }

    }

    &.ant-input-affix-wrapper-disabled {
      border-color: var(--box-border-color--light) !important;

      input {
        color: var(--field-color--disabled--light);
        &::-webkit-input-placeholder {
          color: rgba(143, 155, 179, 0.48);
        }

        &::placeholder {
          color: rgba(143, 155, 179, 0.48);
        }
      }

      .ant-input-suffix {
        color: var(--field-color--disabled--light);
      }
    }

    &.ant-input-affix-wrapper-focused {
      background-color: #FDFFF5;
      .anticon {
        svg {
          color: var(--primary);
        }
      }

    }
    input {
      background-color: #F7F9FC;
      &:active, &:focus {
        background-color: #FDFFF5;
      }

      &::-webkit-input-placeholder {
        color: var(--secondary-text-color--light);
      }

      &::placeholder {
        color: var(--secondary-text-color--light);
      }
    }
  }
}
