.info-section {
  margin: 28px 0 0 0;

  &__title {
    font-weight: bold;
    font-size: 15px;
    text-align: center;
  }

  &__text {
    text-align: left;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
  }
}
