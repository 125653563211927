@import './_mixins.scss';

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  font-size: 15px;
}

:root {
  --primary: #fdfff5;
  --secondary: #FFA811;
  --success: #69C779;
  --success-400: #44b458;
  --tertiary: #FFA811;
  --danger: #FF463D;
  --continue: #2b6be4;
  --primary-bg-dark: #1b1b1b;
  --primary-bg-light: #fdfff5;
  --primary-text-color--light: #222B45;
  --primary-text-color--dark: #FDFFF5;
  --secondary-text-color--light: #8F9BB3;
  --secondary-border-color--light: #E4E9F2;
  --btn-disabled-bg: rgba(27, 27, 27, 0.24);
  --btn-disabled-color: rgba(143,155,179,0.48);
  --field-border--disabled--dark: #101426;
  --field-color--disabled--dark: rgba(143,155,179,0.48);
  --field-border--disabled--light: rgba(143,155,179,0.24);
  --field-color--disabled--light: rgba(143,155,179,0.48);
  --primary-input-bg--dark: #248068;
  --primary-input-bg--light: #7be7b83d;
  --primary-input-bg-dark-focused: #24594280;
  --primary-input-placeholder--light: #8F9BB3;
  --primary-input-placeholder--dark: #8e94a3;
  --primary-input-button--dark: #248068f5;
  --continue-button-dark: #d6ff0f;
  --box-border-color--dark: #101426;
  --box-border-color--light: #E4E9F2;
  --top-bar-height: 56px;
  --max-form-width: 768px;
}

[theme="dark"] {
  &:is(body) {
    background-color: var(--primary-bg-dark);
  }
  .app-wrapper, .ant-modal .ant-modal-content {
    background-color: var(--primary-bg-dark);
    color: var(--primary-text-color--dark);
  }
  h1, h2, h3, h4, h5, h6, .ant-typography:not(.ant-typography-danger) {
    color: var(--primary-text-color--dark);
  }

  .theme-switch-btn.ant-btn {
    box-shadow: 2px 4px 5px 1px rgba(146, 86, 31, 0.62);
  }

  .kuaay-form {
    &__text-under-input {
      color: var(--primary-text-color--dark);
    }
  }

  .ant-radio-wrapper {
    color: #ffffff;

    .ant-radio {
      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: var(--primary);
          &::after {
            transform: scale(0.6)
          }
        }
      }
      .ant-radio-inner {
        background-color: transparent;
        border-color: var(--secondary-text-color--light);
        &::after {
          background-color: var(--primary);
          border-radius: 8px;
        }
      }
    }
  }

  .ant-modal {
    .ant-modal-close-x {
      svg {
        color: var(--primary-text-color--dark);
      }
    }
  }

  .kuaay-form {
    color: var(--primary-text-color--dark);

    .ant-form-item {
      .ant-form-item-label {
        color: var(--secondary-text-color--light);
      }
    }
  }
}

[theme="light"] {
  &:is(body) {
    background-color: var(--primary-bg-light);
  }
  .app-wrapper, .ant-modal .ant-modal-content {
    background-color: var(--primary-bg-light);
    color: var(--primary-text-color--light);
  }
  h1, h2, h3, h4, h5, h6, .ant-typography {
    color: var(--primary-text-color--light);
  }

  .ant-radio-wrapper {
    color: var(--primary-text-color--light);

    .ant-radio {
      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: var(--primary);
          &::after {
            transform: scale(0.6)
          }
        }
      }

      .ant-radio-inner {
        background-color: transparent;
        border-color: var(--secondary-text-color--light);
        &::after {
          background-color: var(--primary);
          border-radius: 8px;
        }
      }
    }
  }

  .ant-modal {
    .ant-modal-close-x {
      svg {
        color: var(--primary-text-color--light);
      }
    }
  }

  .kuaay-form {
    color: var(--secondary-text-color--light);

    .ant-form-item {
      .ant-form-item-label {
        color: var(--secondary-text-color--light);
      }
    }
  }
}

body {
  h4, h4.ant-typography {
    margin-block-start: 10px;
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 800;
  }
  h3, h3.ant-typography {
    margin-block-start: 10px;
    margin-bottom: 10px;
    font-size: 32px;
    font-weight: 800;
  }

  .kuaay-form {
    &__text-under-input {
      color: var(--primary-text-color--light);
    }
  }
}


.theme-switch-btn.ant-btn {
  z-index: 2001;
  width: 40px;
  position: fixed;
  right: 16px;
  bottom: 16px;
  box-shadow: 2px 4px 5px 1px rgba(0, 0, 0, 0.62);
}

.kuaay-input-icon {
  > svg {
    width: 24px;
    height: 24px;
    color: var(--primary);
  }
}

.kuaay-form {
  .ant-form-item {
    margin-bottom: 12px;

    &.ant-form-item-has-error {
      margin-bottom: 24px;
    }

    .ant-form-item-label {
      font-size: 12px;
      font-weight: 800;
    }
  }

  &__text-under-input {
    text-align: left;
  }
}

.ant-form-item-explain-error {
  text-align: left  ;
}

.slide-enter,
.slide-exit {
  transition: transform 100ms ease-out;
}

.slide-enter {
  transform: translateX(-100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}

.text-underline {
  text-decoration: underline;
}

.ant-message {
  top: unset !important;
  bottom: 8px;
  .ant-message-notice {
    &.ant-message-notice-error{
      .ant-message-notice-content {
        border-left-color: #FE6301;
        .anticon {
          color: #FE6301;
        }
      }
    }
    &.ant-message-notice-success{
      .ant-message-notice-content {
        border-left-color: var(--success);
        .anticon {
          color: var(--success);
        }
      }
    }
    &.ant-message-notice-info{
      .ant-message-notice-content {
        border-left-color: #87CEFA;
        .anticon {
          color: #87CEFA;
        }
      }
    }
    &.ant-message-notice-info{
      .ant-message-notice-content {
        border-left-color: #87CEFA;
        .anticon {
          color: #87CEFA;
        }
      }
    }
    .ant-message-notice-content {
      border-radius: 4px;
      border-left-width: 5px;
      border-left-style: solid;
      .ant-message-custom-content {
        display: flex;
        flex-direction: row;
        .ant-message-notice__content {
          display: flex;
          flex-direction: row;
          align-items: center;
          .ant-message-notice__text-wrapper {
            .ant-message-notice__first-line {
              font-size: 12px;
              color: var(--primary-text-color--light);
              font-weight: bold;
              line-height: 14px;
              text-align: left;
            }
            .ant-message-notice__second-line {
              font-size: 10px;
              color: var(--primary-text-color--light);
              line-height: 14px;
              text-align: left;
            }
          }
          .anticon {
            margin-left: 16px;
            color: var(--primary-text-color--light)
          }
        }
      }
    }
  }
}

.font-weight-400 {
  font-weight: 400 !important;
}

/*Hide Input Number Arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.font-weight-bold {
  font-weight: bold !important;
}

.text-center {
  text-align: center !important;
}

.ant-modal-wrap {
  &.ant-modal-centered {
    .ant-modal {
      @include for-mobile-device {
        top: -50px;
      }
    }
  }
  .ant-modal {
    .ant-modal-content {
      border-radius: 4px;
      .ant-modal-body {
        .header-layout {
          max-width: 100% !important;
        }
      }
    }
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 0.5;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.text--error {
  color: var(--danger);
}

.text--primary {
  color: var(--primary);
}

.transaction-form-wrapper {
  min-height: var(--min-form-height);

  .transaction-form__btn {
    margin: 0 auto 16px auto;
    width: calc(var(--app-width) - 100px);

    @include for-tablet-and-up {
      width: 400px;
    }
  }
}




