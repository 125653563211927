.kuaay-datepicker {
  border-width: 1px;
  border-style: solid;
  min-height: 40px;
  border-radius: 4px;
  font-size: 15px;
  width: 100%;
  .ant-picker-input {
    padding-top: 2px;

    input {
      font-size: 15px;
    }
    .ant-picker-suffix {
      .anticon {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    .ant-picker-clear {
      width: 20px;
      height: 20px;
      svg {
        font-size: 20px;
      }
    }
  }
}

[theme="dark"] {
  .kuaay-datepicker {
    // background-color: var(--primary-input-bg--dark);
    background-color: #FDFFF5;
    border-color: var(--primary);
    color: #ffffff;

    &.ant-picker-disabled {
      border-color: var(--box-border-color--dark);
      color: var(--field-color--disabled--light);

      .ant-picker-input {
        input {
          color: var(--field-color--disabled--light);
        }

        .ant-picker-suffix {
          color: var(--field-color--disabled--light);
        }
      }

    }

    .ant-picker-input {
      input {
        // color: var(--primary-text-color--dark);

        &::-webkit-input-placeholder {
          /* Edge */
          color: var(--primary-input-placeholder--dark);
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: var(--primary-input-placeholder--dark);
        }

        &::placeholder {
          color: var(--primary-input-placeholder--dark);
        }
      }

      .ant-picker-suffix,
      .ant-picker-clear {
        color: var(--primary);
        background-color: transparent;
      }
    }
  }

  .ant-picker.kuaay-datepicker.ant-picker-status-error {
    // background-color: var(--primary-input-bg--dark);
    background-color: darkgray;
    &:hover, &:active {
      // background-color: var(--primary-input-bg--dark);
      background-color: darkgray;

    }
  }
}

[theme="light"] {
  .kuaay-datepicker {
    background-color: var(--primary-input-bg--light);
    border-color: var(--primary);
    color: var(--primary-bg-dark);

    &.ant-picker-disabled {
      border-color: var(--box-border-color--light);
      color: var(--field-color--disabled--light);

      .ant-picker-input {
        input {
          color: var(--field-color--disabled--light);
        }

        .ant-picker-suffix {
          color: var(--field-color--disabled--light);
        }
      }

    }

    .ant-picker-input {
      input {
        color: var(--primary-text-color--light);

        &::-webkit-input-placeholder {
          /* Edge */
          color: var(--primary-input-placeholder--light);
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: var(--primary-input-placeholder--light);
        }

        &::placeholder {
          color: var(--primary-input-placeholder--light);
        }
      }

      .ant-picker-suffix,
      .ant-picker-clear {
        color: var(--primary);
        background-color: transparent;
      }
    }
  }

  .kuaay-datepicker.ant-picker-status-error {
    background-color: var(--primary-input-bg--light);
  }

  .ant-picker.kuaay-datepicker.ant-picker-focused {
      &:hover, &:active {
        background-color: var(--primary-input-bg--light);
      }
  }
}
