.stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
  
    .step {
      display: flex;
      text-align: center;
  
      .circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e0e0e0;
        color: #6d6d6d;
        font-weight: bold;
      }
  
      &.active .circle {
        background-color: #3f51b5;
        color: white;
      }
      .line-label-container {
        display: flex;
        flex-direction: column; // Stack line and label vertically
        align-items: center;
        margin-left: 8px; // Space between circle and line
  
        .line {
          width: 50px;
          height: 4px;
          background-color: #e0e0e0;
          margin-bottom: 8px; // Space between line and label
        }
  
        .label {
          font-size: 12px;
          color: #6d6d6d;
          text-align: center;
        }
      }
    }
  }
  