@import 'src/styles/_mixins.scss';

[theme="light"] {
  .navigation {
    //background-color: var(--primary-bg-light);

    &[data-slide-position="top"]:is([data-slide-visible="true"]) {
      .navigation-item {
        border-top-color: var(--primary-bg-light);
        &.active {
          border-top-color: var(--primary);
        }
      }
    }

    &[data-slide-position="bottom"]:is([data-slide-visible="true"]) {
      .navigation-item {
        border-bottom-color: var(--primary-bg-light);
        &.active {
          border-bottom-color: var(--primary);
        }
      }
    }
  }
}

[theme="dark"] {
  .navigation {
    //background-color: var(--primary-bg-dark);


    &:is([data-slide-position="top"]):is([data-slide-visible="true"]) {
      .navigation-item {
        border-top-color: var(--primary-bg-dark);
        &.active {
          border-top-color: var(--primary);
        }
      }
    }

    &:is([data-slide-position="bottom"]):is([data-slide-visible="true"]) {
      .navigation-item {
        border-bottom-color: var(--primary-bg-dark);
        &.active {
          border-bottom-color: var(--primary);
        }
      }
    }
  }
}

.navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;

  &[data-slide-position="top"]:is([data-slide-visible="true"]) {
    .navigation-item {
      border-top-width: 4px;
      border-top-style: solid;
      border-top-color: var(--primary-bg-dark);
    }
  }

  &[data-slide-position="bottom"]:is([data-slide-visible="true"]) {
    .navigation-item {
      border-bottom-width: 4px;
      border-bottom-style: solid;
      border-top-color: var(--primary-bg-dark);
    }
  }

  .navigation-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    transition: all 0.3s ease;
    cursor: pointer;
    color: var(--primary);
    &.active {
      color: var(--primary);
      font-weight: bold;
      svg {
        color: var(--primary);
      }
    }
    .nav-item__icon-container {
      height: 24px;
    }
    .navigation-item__title-container {
      display: flex;
      .navigation-item__title {
        margin-top: 5px;
        font-size: 12px;
        margin-bottom: 0;
        text-align: center;
      }
    }


    .anticon {
      font-size: 20px;
    }
  }
}
