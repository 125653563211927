@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .new-transaction-order-confirmation {
    .new-transaction-order-confirmation__content-wrapper {
      border-color: var(--box-border-color--dark);
    }
  }
}

[theme="light"] {
  .new-transaction-order-confirmation {
    .new-transaction-order-confirmation__content-wrapper {
      border-color: var(--secondary-border-color--light);
    }
  }
}


.new-transaction-order-confirmation {
  .new-transaction-order-confirmation__content-wrapper {
    border-style: solid;
    border-width: 5px;
    border-radius: 4px;
    margin-top: 32px;
  }
  .new-transaction-order-confirmation__title {
    padding: 0 28px;
    text-align: center;
  }
  .new-transaction-order-confirmation__description {
    text-align: center;
  }
  .new-transaction-order-confirmation__redirect-note {
    color: var(--tertiary);
    text-align: center;
    font-size: 18px;
  }
  .transaction-detail-actions {
    .transaction-detail-actions__group {
      width: 600px;
      @include for-mobile-device {
        width: 80%;
      }
    }
    .tap-to-see-more {
      font-size: 18px;
    }
  }
}
