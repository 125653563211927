.kuaay-odometer-unit {
  border: 1px solid #44A8FF;
  padding: 1px 6px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 400;
  line-height: 20px;
}

[theme="dark"] {
  .kuaay-odometer-unit {
    color: var(--primary-text-color--dark)
  }
}

[theme="dark"] {
  .kuaay-odometer-unit {
    color: var(--primary-text-color--light)
  }
}
