@import 'src/styles/_mixins.scss';

[theme="dark"] {

  .transaction-fee-info-wrapper {
    .transaction-fee-info__section {
      h5.ant-typography {
        color: #FFFFFF;
      }
    }

    .transaction-fee-line-item {
      .transaction-fee-line-item__icon {
        background-color: var(--secondary-text-color--light);
      }

      .transaction-fee-line-item__title {
        color: var(--secondary-text-color--light);
      }
    }

    .transaction-fee__total-fee-wrapper {
      .transaction-fee__total-fee__title {
        color: var(--secondary-text-color--light);
      }
    }
  }
}

[theme="light"] {

  .transaction-fee-info-wrapper {
    .transaction-fee-info__section {
      h5.ant-typography {
        color: var(--primary-text-color--light);
      }
    }

    .transaction-fee-line-item {
      .transaction-fee-line-item__icon {
        background-color: var(--secondary-text-color--light);
      }

      .transaction-fee-line-item__title {
        color: var(--secondary-text-color--light);
      }
    }

    .transaction-fee__total-fee-wrapper {
      .transaction-fee__total-fee__title {
        color: var(--secondary-text-color--light);
      }
    }
  }

}

.transaction-fee-info-wrapper {
  padding-top: 30px;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .transaction-fee-info__section {
    h5.ant-typography {
      margin: 0;
      font-size: 22px;
      font-weight: 800;
    }
  }

  .transaction-fee-line-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px 0 16px 0;

    .transaction-fee-line-item__icon {
      width: 18px;
      height: 18px;
      border-radius: 9px;
    }

    .transaction-fee-line-item__title {
      width: 60%;
      font-size: 15px;
      font-weight: bold;
      margin-left: 5px;
    }

    .transaction-fee-line-item__amount {
      flex-grow: 1;
      text-align: left;
      font-size: 18px;
      font-weight: 800;
    }
  }

  .transaction-fee__total-fee-wrapper {
    margin-top: 10px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;

    .transaction-fee__total-fee__title {
      font-size: 32px;
      font-weight: bold;
      width: 45%;
      text-align: center;
    }

    .transaction-fee__total-fee__amount {
      font-size: 36px;
      font-weight: 800;
    }
  }

  .transaction-fee__note-wrapper {
    width: 100%;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
  }

  .transaction-fee__divider {
    margin: 16px 0;
  }

  .transaction-fee-info__mail-section {
    padding: 30px 0 15px 0;

    .transaction-fee-info__mail-address {
      margin-top: 12px;
      padding-left: 32px;
      font-size: 18px;
      font-weight: 800;
    }
  }

  .transaction-fee-info__note-section {
    .transaction-fee-info__note-item {
      padding: 4px 0;
      font-size: 22px;
      line-height: 30px;
      font-weight: normal;
    }
  }

}
