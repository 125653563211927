@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .shipments-page {
    .shipments__section {
      .shipments__section__label {
        color: var(--secondary-text-color--light);
      }
      .shipments__delivery-section__value {
        line-height: 26px;
        a {
          color: green;
        }
      }
    }
  }
}

[theme="light"] {
  .shipments-page {
    .shipments__section {
      .shipments__section__label {
        color: var(--secondary-text-color--light);
      }
      .shipments__delivery-section__value {
        a {
          color: blue;
        }
      }
    }
  }
}

.shipments-page {
  padding-top: 24px;
  padding-bottom: 100px;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .new-quote-btn {
    margin-top: 16px;
    width: 400px;
    @include for-smartphone-only {
      width: 80%;
    }
  }

  .shipments__title {
    font-size: 26px;
    font-weight: 800;
    text-align: left;
  }

  .shipments__shipment-list {
    margin-top: 24px;
    .shipment-card {
      margin-top: 24px;
    }
  }
}

