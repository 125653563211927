@import 'src/styles/_mixins.scss';

.new-transaction-next-user {
  text-align: center;
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__text-wrapper {
    text-align: left;
  }

  &__phone-form-item {
    margin-bottom: 16px;
  }

  .new-transaction-next-user__use-this-device-btn {
    margin-top: 16px !important;
  }
}

[theme="dark"] {
  .new-transaction-next-user {
    background-color: var(--primary-bg-dark);

    .new-transaction-next-user__title {
      color: var(--primary-text-color--dark);
    }
  }
}

[theme="light"] {
  .new-transaction-next-user {
    background-color: var(--primary-bg-light);

    .new-transaction-next-user__title {
      color: var(--primary-text-color--light);
    }
  }
}
