@import 'src/styles/_mixins.scss';

.transaction-fee-info-modal.ant-modal {
  width: 100vw;
  height: 100vh;
  margin: 0;
  top: 0;
  left: 0;

  .ant-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
    padding: 0;
    .transaction-fee-info-modal__content {
      padding-top: 30px;

      @include for-desktop-and-upper {
        max-width: var(--max-form-width);
        margin: auto;
      }



      .transaction-fee-info__actions {
        padding: 48px 0;
        justify-content: center;

        .ant-btn {
          width: 80%;
        }
      }

    }
  }
}
