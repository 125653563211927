@import 'src/styles/_mixins.scss';

.shipping-info {
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__title {
    text-align: center;
    font-size: 26px;
    font-weight: 800;
  }

  &__form-switch-field {
    .shipping-info__form-switch-label {
      line-height: 30px;
      margin-left: 12px;
    }
  }

  &__form-shipping-date-label {
    margin-bottom: 8px;
  }

  &__form-checkbox-field {
    display: flex;
    text-align: left;
    span.ant-typography {
      margin-left: 6px;
    }
  }

}

[theme="dark"] {
  .shipping-info {
    background-color: var(--primary-bg-dark);
    .shipping-info__title {
      color: var(--primary-text-color--dark);
    }

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--dark)
      }
    }
  }
}

[theme="light"] {
  .shipping-info {
    background-color: var(--primary-bg-light);
    .shipping-info__title {
      color: var(--primary-text-color--light);
    }

    &__form-checkbox-field {
      span.ant-typography {
        color: var(--primary-text-color--light)
      }
    }
  }
}
