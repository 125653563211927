[theme="dark"] {
  .transaction-card {
    color: #ffffff;
    background-color: var(--primary-bg-dark);
    border-color: var(--box-border-color--dark);

    &[data-is-focus="true"] {
      background-color: #1c243d;
    }

    .ant-card-head {
      color: #ffffff;
      border-bottom-color: var(--box-border-color--dark);
    }

    .ant-card-body {
      .transaction-card__status {
        color: var(--secondary-text-color--light);
      }
    }
  }
}

[theme="light"] {
  .transaction-card {
    color: var(--primary-text-color--light);
    background-color: #ffffff;
    border-color: var(--box-border-color--light);
    * {
      color: var(--primary-text-color--light);
    }
    &[data-is-focus="true"] {
      background-color: rgb(244, 245, 248);
    }

    .ant-card-head {
      border-bottom-color: var(--box-border-color--light);
    }

    .ant-card-body {
      .transaction-card__status {
        color: var(--secondary-text-color--light);
      }
    }
  }
}

.transaction-card.ant-card {
  width: 100%;
  border-radius: 4px;
  --txn-border-top-color: #44A8FF;
  cursor: pointer;

  &.transaction-card {
    &--primary {
      --txn-border-top-color: #3366FF;
    }
    &--success {
      --txn-border-top-color: #4CE051;
    }
    &--warning {
      --txn-border-top-color: #FFA811;
    }
    &--info {
      --txn-border-top-color: #44A8FF;
    }
  }

  .ant-card-head {
    border-radius: 4px 4px 0 0 ;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: var(--txn-border-top-color);
    padding: 16px 24px;
    .transaction-card__title {
      font-size: 18px;
      font-weight: 800;
      .alert-icon {
        margin-left: 4px;
        svg {
          color: red;
          font-size: 18px;
        }
      }
    }

    .transaction-card__subtitle {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .ant-card-body {
    padding: 16px 24px;

    .transaction-card__pending-detail-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .transaction-card__qr-icon {
        svg {
          font-size: 48px;
        }
      }
    }

    .transaction-card__price {
      font-size: 15px;
      font-weight: 600;
    }

    .transaction-card__status {
      font-size: 12px;
      font-weight: 600;
    }
  }

}
