@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .edit-info-modal.k-modal {
    .ant-col.ant-form-item-label {
      label {
        color: #8e94a3;
      }
    }
  }
}

[theme="light"] {
  .edit-info-modal.k-modal {
    .ant-col.ant-form-item-label {
      label {
        color: #8e94a3;
      }
    }
  }
}

.edit-info-modal.k-modal {
  .edit-info-modal__content {
    @include for-desktop-and-upper {
      max-width: var(--max-form-width);
      margin: auto;
    }
  }
  .edit-info-modal__title {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
    font-size: 32px;
    font-weight: 700;
  }

  .edit-info-modal__new-password-match {
    border-color: #69C779;
  }

  .edit-info-modal__new-password-not-match {
    border-color: var(--danger);
  }

  .ant-col.ant-form-item-label {
    padding: 0;
    label {
      font-size: 12px;
      font-weight: 800;
    }
    .ant-form-item-required::before {
      display: none;
    }
  }

  .edit-info-modal__error-message {
    text-align: center;
    span {
      color: #ff4d4f !important;
    }
  }

  .edit-info-modal__btn-group {
    margin-top: 80px;
    .edit-info-modal__left-col-btn {
      padding-right: 14px;
    }
    .edit-info-modal__right-col-btn {
      padding-left: 14px;
    }
    .edit-info-modal__btn.kuaay-btn {
      width: 100%;
      .anticon+span {
        margin-inline-start: 20px;
      }
    }
  }

}


