@import 'src/styles/_mixins.scss';

.description-form__btn {
    display: flex;
    justify-content: center;
    margin: 0 auto 16px auto;
    width: calc(var(--app-width) - 100px);

    @include for-tablet-and-up {
      width: 400px;
    }
  }

  .slider-wrapper {
    display: flex;
    align-items: center;
  
    span {
      margin: 0 10px;
      color: white;
    }
  
    .ant-slider {
      flex-grow: 1;
    }
    &:hover {
      background-color: #D6ff0f !important; // Change background color on hover
      transform: scale(1.1) !important; // Enlarge button on hover
      color: #000; // Change text color to black on hover
    }
  }

  .description-textarea {
    background-color: white;
    border-radius: 8px;  // Adjust for roundness
    padding: 15px;       // Optional: Add padding for text spacing
    border: 1px solid #d9d9d9; // Light gray border for subtle styling
  
    &.ant-input {
      color: #000; // Black text color for contrast
    }
  }

  .text-area-wrapper {
    position: relative;
  }
  
  .text-area-copy-button {
    position: absolute;
    top: 10px;
    right: 0px;
    z-index: 1;
    background-color: transparent;
    border: none;
    cursor: pointer;

    
  }
  
  .copy-icon {
    font-size: 24px;
    color: black !important; // Set color specifically for this icon
    transition: color 0.3s;
  }
  
  .copy-icon:hover {
    color: rgb(0, 132, 255) !important; // Keep the hover color
  }

  
  .text-area-padding {
    padding-top: 60px !important; 
  }
  
  .text-area-scroll-wrapper {
    max-height: 550px; 
    overflow-y: auto;
  }

  .copied-text {
    color: rgb(0, 0, 0); 
  }

  .spinner{
    overflow-x: hidden !important;
  }