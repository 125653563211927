@import 'src/styles/_mixins.scss';

.new-shipment-order-confirm-page {
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .new-shipment-order-confirm-page__title {
    text-align: center;
  }

  .new-shipment-order-confirm-page__content-wrapper {
    padding: 24px 32px;
  }


  .new-shipment-order-confirm-page__actions {
    display: flex;
    flex-direction: column;
    margin: auto;

    .start-new-quote-btn {
      width: 400px;
      @include for-smartphone-only {
        width: 100%;
      }
    }

  }

}

[theme="dark"] {
  .new-shipment-order-confirm-page {
    background-color: var(--primary-bg-dark);

    .new-shipment-order-confirm-page__content-wrapper {
    }
  }
}

[theme="light"] {
  .new-shipment-order-confirm-page {
    background-color: var(--primary-bg-light);

    .new-shipment-order-confirm-page__content-wrapper {
    }
  }
}
