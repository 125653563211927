@import 'src/styles/_mixins.scss';

.share-confirmation-page {
  text-align: center;
  padding-top: 24px;
  margin-top: 65px;
  padding-bottom: 20px;
  min-height: 100%;
  border: 5px solid rgba(0, 0, 0, 0.5);

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: 65px auto;
  }

  &__title {
    font-size: 26px;
    font-weight: 800;
  }

  &__text-wrapper {
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;

    .kuaay-btn {
      width: 300px;

      &.share-confirmation-page__actions__share-btn {
        margin-top: 24px;
        background-color: var(--tertiary);
      }

      &.share-confirmation-page__actions__done-btn {
        margin-top: 24px
      }
    }
  }

  &__continue-button {
    width: 100%;
  }
}

[theme="dark"] {
  .share-confirmation-page {
    background-color: var(--primary-bg-dark);

    &__title {
      color: var(--primary-text-color--dark);
    }

    &__text-wrapper {
      &__share-link {
        color: green !important;
      }
    }
  }
}

[theme="light"] {
  .share-confirmation-page {
    &__title {
      color: var(--primary-text-color--light);
    }

    &__text-wrapper {
      &__share-link {
        color: blue !important;
      }
    }
  }
}
