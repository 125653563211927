.widget-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: calc(33.33% - 40px); 
  max-width: 300px;
  min-width: 250px;
  height: 200px;
  max-height: 85vh; /* Maximum height based on viewport height */
  // background-color: #2b6be4;
  background: linear-gradient(180deg, #1364ff 40.5%, #0b3c99);
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: white;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  overflow: hidden; /* Prevent overflow if content is too large */


  h3 {
    margin: 0;
    font-size: 1.5rem;
  }

  p {
    margin: 5px 0;
  }

  &:hover {
    transform: scale(1.05);
    background: #D6FF0F ; // Change background to yellow on hover
    color: black;
    .icon .anticon {
      color: black !important;
    }
  }
}
  
  .background {
    // border-radius: var(--br-lg-4);
    border-radius: 15px; /* Optional: adjust border-radius here */
    background-color: var(--color-mediumslateblue-200);
    // padding: var(--padding-7xl-4) var(--padding-12xl) var(--padding-xl)
    //   var(--padding-19xl);
    padding: 1rem 1.5rem; /* Adjust padding for responsiveness */
    z-index: 1;
    max-height: 100%; /* Ensure the inner content doesn't exceed widget height */
    overflow: hidden;
  }
  
  .widget-title {
    position: relative;
    line-height: 36.4px;
    display: flex;
    text-align: left; /* Align the text within the container to the left */
    align-items: center;
    justify-content: flex-start; /* Aligns text to the left */
    font-size: 1.5rem;
    padding-bottom: 1rem;
    padding-left: 15px;
    width: 100%; /* Take the full width of the container */
    word-wrap: break-word; /* Allow long words to break onto new lines */
    white-space: normal; /* Ensure normal line breaks */
  }
  
  .widget-details {
    display: flex;

    flex-direction: row;
    // gap: var(--gap-12xs-2);
    gap: 0.5rem;

    margin-top: -6.5px;
    // font-size: var(--font-size-5xs-6);
    font-size: 0.75rem;

    &.no-description {
      // justify-content: lefts; 
      justify-content: flex-start;
    }
  }

  .description-wrapper {
    flex: 1;
    overflow: hidden; 
    white-space: normal; 
    text-overflow: ellipsis; 
  }
  
  .description-text {
    flex: 1;
    padding-left: 15px;
    position: relative;
    line-height: 14px;
    text-align: left;  
    font-size: 0.875rem; 
    line-height: 1.2; 
    white-space: normal;
    word-wrap: break-word; 
  }
  
  .icon {
    width: 60px;
    height: 80px;
    object-fit: contain;
    padding-left: 50px;
    padding-right:10px;
    font-size: 3rem !important;
    transition: color 0.2s ease-in-out !important;  // Smooth color transition
  }
  
  .action-button {
    margin-top: 10px;
    font-size: 0.75rem; 
    padding: 4px 8px; 
    height: auto; 
  }
  
.widget-details,
.featureContainer {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-height: 700px) {
  .widget-frame {
    height: 150px; /* Adjust for smaller viewports */
    max-height: 80vh;
  }

  .background {
    padding: 0.8rem 1rem; /* Reduced padding for smaller viewports */
  }

  .description-text {
    font-size: 0.60rem; /* Smaller font size for lower heights */
  }
}

@media (max-width: 500px) {
  .widget-frame {
    flex-basis: calc(50% - 20px); /* Stack widgets in 2 columns on smaller screens */
  }
}