@import 'src/styles/_mixins.scss';


.new-transaction-page {
  text-align: center;
  padding-bottom: 20px;
  @include for-desktop-and-upper {
    margin: auto;
    max-width: var(--max-form-width);
  }

  &__title {
    font-size: 26px;
    font-weight: 800;
  }

  &__text-under-title {
    font-size: 15px;
    font-weight: 400;
  }

  .new-transaction-page__entity-type {
    margin-top: 8px;
    .entity-type__business {
      margin-left: 44px;
      margin-right: 0;
    }
  }

  &__buyer-infor-form {
    margin-top: 15px;
  }

  &__continue-button {
    width: 100%;
  }
}

[theme="dark"] {
  .new-transaction-page {
    background-color: var(--primary-bg-dark);
    .new-transaction-page__title,
    .new-transaction-page__text-under-title {
      color: var(--primary-text-color--dark);
    }

    .new-transaction-page__entity-type {
      span {
        color: var(--primary-text-color--dark);
      }
    }
  }
}

[theme="light"] {
  .new-transaction-page {
    .new-transaction-page__title,
    .new-transaction-page__text-under-title {
      color: var(--primary-text-color--light);
    }

    .new-transaction-page__entity-type {
      span {
        color: var(--primary-text-color--light);
      }
    }
  }
}
