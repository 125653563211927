@import 'src/styles/_mixins';

.checkout-form {
  padding-top: 40px;
  .card-info-container {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: solid 1px var(--primary);
    .card-info__number-wrapper {
      padding: 8px;
      border-bottom: solid 1px var(--primary);
    }
    .card-info__expiry-and-cvc {
      display: flex;
      flex-direction: row;
      .card-info__expiry-wrapper {
        padding: 8px;
        flex-grow: 2;
        width: 60%;
      }
      .card-info__cvc-wrapper {
        border-left: solid 1px var(--primary);
        padding: 8px;
        flex-grow: 1;
        width: 40%;
      }
    }
  }

  .checkout-form__otp-input-container {
    width: 100%;

    .checkout-form__otp-input {
      width: 32px !important;
      height: 48px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  .billing-address-container {
    .kuaay-input {
      background-color: transparent !important;
    }
  }

  .checkout-form__error-message {
    color: var(--danger);
  }

  .checkout-form__pay-btn {
    .checkout-form__pay-btn__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .checkout-form__stripe-powered {
    width: 100%;
    text-align: center;
    color: var(--secondary-text-color--light);
    a {
      color: inherit;
    }
  }

}

.checkout-modal-root {
  .checkout-modal-wrap {
    @include for-smartphone-only {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .checkout-modal {
        top: unset;
      }
    }

    .ant-modal-close {
      inset-inline-end: unset;
    }
  }
}
