@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .navigation.bottom-navigation {
    background-color: var(--primary-bg-dark);
  }
}

[theme="light"] {
  .navigation.bottom-navigation {
    background-color: var(--primary-bg-light);
  }
}

.navigation.bottom-navigation {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 64px;
  padding-bottom: 24px;
  box-sizing: content-box;
  overflow: hidden !important; 

  @include for-desktop-and-upper() {
    display: none !important;
  }

  .navigation-item {
    padding-top: 4px;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .alert-icon {
    margin-left: 4px;
    svg {
      color: red !important;
      font-size: 16px;
    }
  }
}

.navigation-item.full-width-logo {
  width: 100%; // Full width for the logo section
  img {
    width: 100%; // Make the logo fill the section
    height: auto; // Maintain the aspect ratio
    object-fit: contain; // Keep the image proportionate
  }
}

.logo-icon {
  width: 28px; // Adjust size based on your design
  height: 28px;
}
.logo-icon.active {
  // Styles for when the logo is active, if needed
}
