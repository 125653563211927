.kuaay-btn.ant-btn {
  border-radius: 4px;
  font-weight: bold;
  min-height: 40px;
  font-size: 14px;
  min-width: 80px;
  &[color="primary"] {
    color: #FDFFF5;
    background-color: var(--continue);
    border-width: 0;
  }
  &[color="secondary"] {
    color: #FDFFF5;
    background-color: var(--secondary);
    border-width: 0;
  }
  &[color="default"] {
    background-color: var(--continue);
    color: #1b1b1b;
    border-width: 0;
  }
  &[color="info"] {
    background-color: #44A8FF;
    color: #FDFFF5;
    border-width: 0;
  }
  &[color="tertiary"] {
    background-color: var(--tertiary);
    color: #FDFFF5;
    border-width: 0;
  }
  &[color="danger"] {
    background-color: var(--danger);
    color: #FDFFF5;
    border-width: 0;
  }
  &[color="success"] {
    background-color: var(--success-400);
    color: #FDFFF5;
    border-width: 0;
  }
  &[disabled] {
    background-color: var(--btn-disabled-bg) !important;
    color: var(--btn-disabled-color) !important;
  }

  &.ant-btn-sm {
    font-size: 12px;
    min-height: 32px;
    padding: 0 10px;
  }
}
