@import 'src/styles/_mixins.scss';

.new-transaction-user-role-page {
  text-align: center;
  padding-bottom: 20px;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__title {
    font-size: 26px;
    font-weight: 800;
  }

  .transaction-form-wrapper {
    .transaction-form__btn {
      margin-top: 24px;
      margin-bottom: 0;
      width: 260px;
    }
  }
}

.self-transfer-modal.ant-modal {
  width: 90%;
  top: 40%;
  .ant-modal-content {
    padding: 20px 36px;
    .ant-modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include for-desktop-and-upper {
        max-width: var(--max-form-width);
        margin: auto;
      }

      .self-transfer-modal__text {
        .self-transfer-modal__child-text {
          font-weight: bold;
        }
      }

      .self-transfer-modal__btn {
        margin-top: 8px;
      }
      .self-transfer-modal__continue-btn {
        margin-left: 12px;
      }
    }
  }
}


[theme="dark"] {
  .new-transaction-user-role-page {
    background-color: var(--primary-bg-dark);
    .new-transaction-user-role-page__title {
      color: var(--primary-text-color--dark);
    }
  }
}

[theme="light"] {
  .new-transaction-user-role-page {
    .new-transaction-user-role-page__title {
      color: var(--primary-text-color--light);
    }
  }
}
