@import 'src/styles/_mixins.scss';

.new-transaction-kyc {
  text-align: center;
  padding-bottom: 20px;
  min-height: 100%;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  &__text-wrapper {
    text-align: left;

    .new-transaction-kyc__first-text {
      text-align: center;
    }
  }

  &__text-policy {
    .new-transaction-kyc__text-policy-link {
      font-weight: 800;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .new-transaction-kyc__continue-btn {
    margin-top: 16px !important;
  }
}

[theme="dark"] {
  .new-transaction-kyc {
    background-color: var(--primary-bg-dark);
  }
}

[theme="light"] {
  .new-transaction-kyc {
    background-color: var(--primary-bg-light);
  }
}
