@import 'src/styles/_mixins.scss';

[theme="dark"] {
  .shipment-detail-page {
    .shipment-detail-page__section {
      .shipment-detail-page__section__label {
        color: var(--secondary-text-color--light);
      }
      .shipment-detail-page__section__content {
        a {
          color: green;
        }
      }
    }
  }
}

[theme="light"] {
  .shipment-detail-page {
    .shipment-detail-page__section {
      .shipment-detail-page__section__label {
        color: var(--secondary-text-color--light);
      }
      .shipment-detail-page__section__content {
        a {
          color: blue;
        }
      }
    }
  }
}

.shipment-detail-page {
  padding-top: 24px;
  padding-bottom: 100px;

  @include for-desktop-and-upper {
    max-width: var(--max-form-width);
    margin: auto;
  }

  .shipment-detail-page__title {
    font-size: 26px;
    font-weight: 800;
    text-align: left;
  }

  .shipment-detail-page__section {
    margin-top: 20px;
    &.shipment-detail-page__actions-section {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .shipment-detail-page__actions__help-btn {
        width: 400px;
        @include for-smartphone-only {
          width: 100%;
        }
      }
    }
    .shipment-detail-page__section__label {
      font-size: 15px;
      font-weight: bold;
    }
    .shipment-detail-page__section__content {
      margin-top: 4px;
      font-size: 22px;
      line-height: 30px;
      font-weight: 800;
      .shipment-detail-page__vehicle-list {
        padding-inline-start: 24px;
        margin: 0;
        .shipment-card__vehicle-info-item {
          &:not(:first-child) {
            margin-top: 10px;
          }
          .shipment-card__vehicle-info-container {

            display: flex;
            flex-direction: row;
            @include for-smartphone-only {
              flex-direction: column;
            }

          }
        }
      }
    }
  }
}

